import {
    PropertyDocument, PropertyInventoryTask,
    PropertyInventoryTaskPart
} from "../../../../../models/property/property";
import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {InventoryTaskStatus} from "../../common/inventory-task-status/inventory-task.status";
import {SystemUser} from "../../../../../models/users/user/system.user";
import {Button, Dropdown, Menu, Space, Tag} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const columnFactory = (
    t: Translate,
    allowActions: boolean,
    onDownload: (p: PropertyInventoryTaskPart) => void,
    onDownloadWriteOffList: (p: PropertyInventoryTaskPart) => void,
    onCreateWriteOff: (p: PropertyInventoryTaskPart) => void,
    onClose: (p: PropertyInventoryTaskPart) => void,
    onDownloadIgnoredList: (p: PropertyInventoryTaskPart) => void,
    onEdit: (p: PropertyInventoryTaskPart) => void,
    task: PropertyInventoryTask
): ColumnsType<PropertyInventoryTaskPart> => {
    const columns: ColumnsType<PropertyInventoryTaskPart> = [
        {
            key: 'type',
            title: t('PROPERTY.PROPERTY.TYPE'),
            dataIndex: 'type',
            render: (v, vv) => <Link to={"/property/inventory-tasks/part/" + vv.id}>{v.name}</Link>,
            width: '310px'
        },
        {
            key: 'qty',
            title: t('QTY'),
            render: (_, t) => ((t.lostQty + t.confirmedQty + t.destroyedQty) + " / " + t.qty),
            width: '120px'
        },
        {
            key: 'qty_to_w-off',
            title: t('PROPERTY.INVENTORY_TASK_PART.QTY_TO_WRITE_OFF'),
            render: (_, t) => t.lostQty + t.destroyedQty,
            width: '120px'
        },
        {
            key: 'document',
            dataIndex: 'document',
            title: t('PROPERTY.INVENTORY_TASK_PART.DOCUMENT'),
            render: (document: PropertyDocument | undefined, part) => (
                <>
                    {document ? (
                        <Space direction={"horizontal"}>
                            <FontAwesomeIcon
                                icon={["fas", "file-pdf"]}
                                onClick={() => onDownloadWriteOffList(part)}
                                style={{cursor: "pointer"}}
                            />
                            <Link
                                to={"/property/inventory-tasks/part/" + part.id + "/write-off"}>
                                {document.number}
                            </Link>
                        </Space>
                    ) : (
                        <Space direction={"horizontal"}>
                            {task.status === "opened" ? (
                                <>
                                    <Button
                                        type={"primary"}
                                        size={"small"}
                                        disabled={(part.lostQty === 0 && part.destroyedQty === 0) || part.status === 'opened'}
                                        onClick={() => onCreateWriteOff(part)}
                                    >
                                        {t("CREATE")}
                                    </Button>
                                    <Button
                                        type={"primary"}
                                        size={"small"}
                                        disabled={part.status !== 'finished'}
                                        onClick={() => onClose(part)}
                                    >
                                        {t("CLOSE")}
                                    </Button>
                                </>
                            ) : null}
                        </Space>
                    )}
                </>
            )
        },
        {
            key: 'status',
            title: t('STATUS'),
            dataIndex: 'status',
            render: (v) => <InventoryTaskStatus status={v} />
        },
        {
            key: 'responsiblePersons',
            title: t('PROPERTY.PROPERTY.RESPONSIBLE_PERSON'),
            dataIndex: 'responsiblePersons',
            render: (ps: SystemUser[]) => <>{ps.map(u => <Tag>{u.firstName + ' ' + u.lastName}</Tag>)}</>
        }
    ]

    if (allowActions) {
        columns.push({
            key: 'actions',
            title: '',
            render: (_, p) => (
                <Dropdown
                    placement={"bottomRight"}
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            <Menu.Item
                                icon={<FontAwesomeIcon icon={["fas", "people-arrows"]} />}
                                onClick={() => onEdit(p)}
                            >
                                Change persons
                            </Menu.Item>
                            <Menu.Item
                                icon={<FontAwesomeIcon icon={["fas", "file-pdf"]} />}
                                onClick={() => onDownload(p)}
                            >
                                Põhivarade inventuur
                            </Menu.Item>
                            <Menu.Item
                                icon={<FontAwesomeIcon icon={["fas", "file-pdf"]} />}
                                onClick={() => onDownloadIgnoredList(p)}
                            >
                                Ignored document
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button
                        type={"text"}
                        icon={<FontAwesomeIcon icon={["fas", "ellipsis-v"]}
                        />}>
                    </Button>
                </Dropdown>
            )
        })
    }

    return columns;
}