import React, {useMemo, useState} from "react";
import {Button, Form, FormInstance, Input, Select, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RenderUploadLabelField} from "./select-courier-label-field";
import {Courier, EditableParcel, Shipment} from "../../../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../../../services/shipments/shipments.service";

export const columnsFactory = (form: FormInstance): ColumnsType<EditableParcel> => {
    return [
        {
            title: 'Number',
            dataIndex: 'number',
            className: 'v-top',
            render: (number: string, info) => <Space direction={"horizontal"} size={[10, 10]} style={{marginTop: 5}}>
                <FontAwesomeIcon icon={"box"} color={"#676767"} />
                <span>{number}</span>
            </Space>
        },
        {
            title: 'Tracking code',
            render: (_, parcel, index) => <Form.Item
                key={index}
                name={["parcels", index.toString(), 'trackCode']}
                rules={[{ required: true, message: 'Tracking code is required' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            title: 'Label',
            width: 100,
            render: (_, parcel, index) => <RenderUploadLabelField parcel={parcel} index={index} />
        },
        {
            title: 'Label page',
            width: 100,
            render: (_, parcel, index) => <Form.Item
                key={index}
                name={["parcels", index.toString(), 'labelPage']}
                rules={[{ required: true, message: 'Tracking code is required' }]}
            >
                <Input />
            </Form.Item>
        }
    ]
}

interface FormValue {
    courier: string;
    parcels: Record<string, EditableParcel>;
}


interface SelectCourierManuallyProps {
    couriers: Courier[];
    shipment: Shipment;
    onComplete: () => void;
}

export const SelectCourierManually: React.FC<SelectCourierManuallyProps> = ({couriers, shipment, onComplete}) => {

    const [form] = Form.useForm();
    const columns = useMemo(() => columnsFactory(form), []);

    const [loading, setLoading] = useState<boolean>(false);

    const editableParcels = useMemo<EditableParcel[]>(() =>
        shipment.parcels.map(parcel => ({parcel: parcel.id, number: parcel.number, type: parcel.type, trackCode: parcel.trackCode})), [shipment.parcels]);

    return <Space direction="vertical" size={[15, 15]}>
            <Space direction="vertical" size={[15, 15]}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{courier: couriers[0].id, parcels: editableParcels.map(v => ({"labelPage": 1, "trackCode": v.trackCode, "parcel": v.parcel}))}}
                    onFinish={(values: FormValue) => {
                        form.validateFields().then(async () => {

                            Object
                                .keys(values.parcels)
                                .forEach((key) => {
                                    values.parcels[key].parcel = editableParcels[parseInt(key)].parcel;
                                    values.parcels[key].labelPage = parseInt(values.parcels[key].labelPage as unknown as string);
                                });

                            setLoading(true);
                            try {
                                await shipmentsService.customReShip(shipment.id, values.courier, Object.values(values.parcels));
                                onComplete();
                            } finally {
                                setLoading(false);
                            }
                        })
                    }}
                >
                    <Form.Item
                        name="courier"
                        label="Courier"
                    >
                        <Select disabled={loading}>
                            {couriers.map(courier => <Select.Option value={courier.id}>{courier.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Table
                        columns={columns}
                        dataSource={editableParcels}
                        rowKey={"id"}
                        pagination={false}
                    />
                </Form>
            </Space>
            <Space direction="horizontal" size={[15, 15]}>
                <Button type={"primary"} onClick={() => form.submit()} disabled={loading}>Go</Button>
            </Space>
    </Space>
}