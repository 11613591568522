import React, {createRef} from 'react';
import {Col, Form, FormInstance, Input, Modal, Row, Select} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {observer} from "mobx-react";
import {Shipment, ShipmentRequest} from "../../../../models/parcels/shipment";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {useRequiredStore} from "../../../../utils/store";
import {ShipmentsStoreContext} from "../../shipments-dropship/shipments-dropship.store";
import dayjs from "dayjs";

interface ShipmentsEditModalProps {
    visible: boolean;
    onSuccess: (data: ShipmentRequest, item: Shipment) => Promise<void>
    onClose: () => void,
}
export const ShipmentsEditModal: React.FC<ShipmentsEditModalProps> = observer(({visible, onSuccess, onClose}) => {
    const store = useRequiredStore(ShipmentsStoreContext);
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm();
    const t = useTranslate();
    const modalRef = createRef<FormInstance>();

    if (!store.editModalItem) {
        return null;
    }

    const defaultValues = {
        status: store.editModalItem.status,
        shippedAt: dayjs(store.editModalItem.packedAt),
        parcels: store.editModalItem.parcels
    }

    return (
        <Modal
            destroyOnClose={true}
            visible={visible}
            title={t("SHIPMENTS.EDIT")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                ref={modalRef}
                layout="vertical"
                form={form}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSuccess(values, store.editModalItem!)
                            .then(
                            () => {
                                !store.modalItem && form.resetFields();
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Form.Item
                    name="status"
                    label={t("STATUS")}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp={"children"}
                        placeholder={t("STATUS")}
                    >
                        {store.statuses.map(status => (
                            <Select.Option value={status} key={status}>{t("SHIPMENTS.STATUS." + status.toUpperCase())}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.List
                    name={"parcels"}
                    rules={[{
                        validator: async (rule, parcels) => {
                            try {
                                if (!parcels || parcels.length === 0) {
                                    return Promise.reject((t("FROM.ERROR.PLEASE_ADD_VALUE")));

                                }
                            } catch (error) {
                                throw new Error(error as string);
                            }
                        }
                    }]}
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <Row justify="space-between" gutter={[10, 5]} key={field.key}>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            label={t("SHIPMENTS.PARCELS.BOX_CODE")}
                                            
                                            name={[field.name, "boxCode"]}
                                            rules={[{
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                            }]}
                                        >
                                            <Input placeholder={t("SHIPMENTS.PARCELS.BOX_CODE")} type={"text"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            label={t("SHIPMENTS.PARCELS.TRACK_CODE")}
                                            
                                            name={[field.name, "trackCode"]}
                                            rules={[{
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                            }]}
                                        >
                                            <Input placeholder={t("SHIPMENTS.PARCELS.TRACK_CODE")} type={"text"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            label={t("TYPE")}
                                            
                                            name={[field.name, "type"]}
                                            rules={[{
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                            }]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp={"children"}
                                                placeholder={t("TYPE")}
                                            >
                                                {store.types.map(type => (
                                                    <Select.Option value={type} key={type}>{t("SHIPMENTS.PARCELS.TYPE." + type.toUpperCase())}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            label={t("NUMBER")}
                                            
                                            name={[field.name, "number"]}
                                            rules={[{
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                            }]}
                                        >
                                            <Input placeholder={t("NUMBER")} type={"text"}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </>
                        )}
                </Form.List>
            </Form>
        </Modal>
    )
});
