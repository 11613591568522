import React, {Key} from 'react';
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {Button, Modal, notification, Row, Space, Table, Typography} from 'antd';
import {ShipmentsStoreContext} from '../../shipments-dropship/shipments-dropship.store';
import {ColumnsType} from "antd/es/table";
import {ParcelItem, ParcelItemOrder} from "../../../../models/parcels/shipment";

interface ParcelsModalProps {
    visible: boolean;
}
export const ShipmentsModal: React.FC<ParcelsModalProps> = observer(({visible}) => {
    const store = useRequiredStore(ShipmentsStoreContext);
    // const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const t = useTranslate();

    if (!store.modalItem || store.fetchingModalItem) {
        return null;
    }

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: message
        });
    }

    const columns: ColumnsType<ParcelItem> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
            render: (name: string) => name ? <a target={"_blank"} href={"/articles/" + name}>{name}</a> : null
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty",
            width: 80,
        },
        {
            title: t("ORDERS.ORDER.TITLE"),
            dataIndex: "order",
            key: "order",
            width: 130,
            render: (item: ParcelItemOrder) => item
                ? <a target={"_blank"} href={"/orders/" + item.id}>{item.id}</a>
                : "-"
        }
    ];


    return (
        <Modal
            visible={visible}
            title={
                <>
                    {t("SHIPMENTS.ITEM.TITLE") + " #" + store.modalItem?.id} {" "}
                    {t("WAREHOUSES.WAREHOUSE_TITLE")}: {store.modalItem?.warehouse.name} {" "}
                    {t("COURIER.TITLE")}: {store.modalItem?.carrier} ({store.modalItem?.carrier.id})
                </>
            }
            onCancel={(event) => {
                event.stopPropagation();
                store.closeModal();
            }}
            footer={
                <Row justify={"end"}>
                    <Button
                        loading={store.dispatching}
                        onClick={async () => {
                            await store.dispatch(openNotification)
                        }}
                        disabled={store.isDispatchDisabled}
                        type={"primary"}
                    >
                        {t("DISPATCH.DO_DISPATCH")}
                    </Button>
                    <Button
                        onClick={() => store.closeModal()}
                    >
                        {t("CANCEL")}
                    </Button>
                </Row>
            }
            confirmLoading={loading}
            width={800}
            centered
        >
            <Space direction={"vertical"} size={[30, 30]}>

                <Space direction={"vertical"} size={[10, 10]}>
                    <Typography.Title level={4} style={{marginBottom: 0}}>{t("SHIPMENTS.PARCEL.ITEMS")}</Typography.Title>
                    {store.modalItem.parcels.map(parcel => (
                        <Space key={parcel.id} direction={"vertical"} size={[0, 0]}>
                            <Typography.Title
                                level={5}
                            >
                                {
                                     t("SHIPMENTS.PARCELS.TRACK_CODE") +
                                    ": " + parcel.trackCode + " " + t("TYPE") + ": " +
                                    t("SHIPMENTS.PARCELS.TYPE." + parcel.type.toUpperCase())
                                }
                            </Typography.Title>
                            <Table
                                className={"shipments-table"}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: () => {
                                            store.onParcelItemClick(parcel, record);
                                        }
                                    };
                                }}
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: store.selectedParcelItemsKeys[parcel.id] ? [...store.selectedParcelItemsKeys[parcel.id]] : [],
                                    checkStrictly: false,
                                    hideSelectAll: true,
                                    onChange: (selectedRowKeys: Key[], selectedRows: ParcelItem[]) => {
                                        store.selectParcelsItems(parcel, selectedRowKeys);
                                    },
                                }}
                                rowKey={"id"}
                                columns={columns}
                                dataSource={parcel.items}
                                scroll={{x: true}}
                                pagination={false}
                            />
                        </Space>
                    ))}
                </Space>
            </Space>
        </Modal>
    );
});
