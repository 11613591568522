import React, {useEffect, useState} from "react";
import {Modal, Space, Tabs} from "antd";
import {Courier, Shipment} from "../../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";
import {SelectCourierAuto} from "./select-courier/select-courier-auto";
import {SelectCourierManually} from "./select-courier/select-courier-manually";

interface ChangeCarrierModalProps {
    shipment: Shipment;
    onCancel: () => void;
    onComplete: () => void;
}

export const ChangeCarrierModal: React.FC<ChangeCarrierModalProps> = ({onCancel, onComplete, shipment}) => {

    const [couriers, setCouriers] = useState<Courier[]>([]);

    useEffect(() => {
        shipmentsService.getCouriers().then(couriers => setCouriers(couriers));
    }, [])

    if (0 === couriers.length) {
        return <>Loading...</>;
    }

    return <Modal
        title={"Change carrier & Create labels"}
        visible={true}
        onCancel={onCancel}
        width={"900px"}
        footer={false}
    >
        <Space direction="vertical" size={[15, 15]}>
            <Tabs>
                <Tabs.TabPane tab={"Auto"} key={1}>
                    <SelectCourierAuto couriers={couriers} shipment={shipment} onComplete={onComplete} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Manually"} key={2}>
                    <SelectCourierManually couriers={couriers} shipment={shipment}  onComplete={onComplete} />
                </Tabs.TabPane>
            </Tabs>
        </Space>
    </Modal>
}