import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {RouteTabs} from "../../../common/route-tabs/route.tabs";
import {Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

export const PropertyDocumentListWrapper: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const t = useTranslate();
    const navigate  = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/property/documents") {
            navigate("/property/documents/inventory-decrease", {replace: true})
        }
    }, [location.pathname])

    return (
        <Space size={[0, 0]} direction={"vertical"}>
            <Typography.Title level={3}>
                {t("PROPERTY.PROPERTY_DOCUMENTS.TITLE")}
            </Typography.Title>
            <RouteTabs routes={routeChildren!} />
        </Space>
    )
}