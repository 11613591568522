import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {Property} from "../../../../../models/property/property";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../../ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";
import {UIInputWidgetPropertyType} from "../../../ui/input/widget/property-type/ui.input.widget.property-type";
import {propertyService} from "../../../../../services/property/property.service";
import {UIInputWidgetPropertyLocation} from "../../../ui/input/widget/property-location/ui.input.widget.property-location";
import dayjs from "dayjs";
import {useNavigate} from "react-router";

interface EditPropertyModalProps {
    onClose: () => void;
    property?: Property | null;
}

export const priceTypes = ["V", "PV", "C"];

export const EditPropertyModal: React.FC<EditPropertyModalProps> = ({ onClose, property }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const initialValues = useMemo(() => {
        let values: any = {}
        if (property) {
            values = {
                code: property.code,
                type: property.type.id,
                description: property.description,
                supplier: property.supplier,
                purchaseDate: dayjs(property.purchaseDate),
                purchaseAmountValue: property.purchaseAmount.value,
                responsiblePerson: property.responsiblePerson.id,
                location: property.location.id,
                requiredPhotoConfirmation: property.requiredPhotoConfirmation
            }
        }

        return values;
    }, [])

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("PROPERTY.PROPERTY_LIST.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        values.purchaseAmountValue = parseFloat(values.purchaseAmountValue);
                        setLoading(true);
                        try {
                            if (property) {
                                await propertyService.editProperty(property.id, values);
                                onClose();
                            } else {
                                const newProperty = await propertyService.createProperty(values);
                                onClose();
                                navigate('/property/' + newProperty.id);
                            }
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields || []);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PROPERTY.PROPERTY.TYPE")}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPropertyType />
                </Form.Item>
                {property ? null : (
                    <>
                        <Form.Item
                            label={t("PROPERTY.PROPERTY.PRICE_TYPE.TITLE")}
                            name="priceType"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <Select>
                                {priceTypes.map(type => (
                                    <Select.Option key={type} value={type}>
                                        {t("PROPERTY.PROPERTY.PRICE_TYPE.VALUE." + type.toUpperCase())}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(v1, v2) => v1.priceType !== v2.priceType}
                        >
                            {() => form.getFieldValue('priceType') === 'C' ? (
                                <Form.Item
                                    label={t("PROPERTY.PROPERTY.CUSTOM_CODE")}
                                    name="customCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                                            ),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    label={t("PROPERTY.PROPERTY.DESCRIPTION")}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {property ? null : (
                    <Form.Item
                        label={t("PROPERTY.PROPERTY.QTY")}
                        name="qty"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "FROM.ERROR.PLEASE_SELECT_VALUE"
                                ),
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                )}
                <Form.Item
                    label={t("PROPERTY.PROPERTY.SUPPLIER")}
                    name="supplier"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("PROPERTY.PROPERTY.PURCHASE_DATE")}
                    name="purchaseDate"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <DatePicker
                        showNow
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item
                    label={t("PROPERTY.PROPERTY.PURCHASE_AMOUNT")}
                    name="purchaseAmountValue"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label={t("PROPERTY.PROPERTY.RESPONSIBLE_PERSON")}
                    name="responsiblePerson"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPropertyResponsibleUser />
                </Form.Item>
                <Form.Item
                    label={t("PROPERTY.PROPERTY.LOCATION")}
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPropertyLocation
                        selectFirstOnLoad={true}
                    />
                </Form.Item>
                {property ? null : (
                    <Form.Item
                        label={t("PROPERTY.PROPERTY.ADD_TO_OPENED_TASKS")}
                        name="addToOpenedTasks"
                        valuePropName={"checked"}
                    >
                        <Checkbox />
                    </Form.Item>
                )}
                <Form.Item
                    label={t("PROPERTY.PROPERTY.REQUIRED_PHOTO_CONFIRMATION")}
                    name="requiredPhotoConfirmation"
                    valuePropName={"checked"}
                >
                    <Checkbox />
                </Form.Item>
            </Form>

        </Modal>
    );
}