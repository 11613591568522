import {Document} from "../document";
import {DocumentWeights} from "../document.weights";
import {DocumentStatus} from "../status";

// export interface InvoiceAccount {
//     agent: Agent
//     id: number
// }
// export interface InvoiceConditions {
//     clientPaymentTerms: number
// }
// export interface InvoicePackingList {
//     id: number,
//     docNumber: string
// }
// export interface InvoiceOrderDetails {
//     id: number,
//     account: InvoiceAccount,
//     status: string,
//     conditions: InvoiceConditions,
//     packingList: InvoicePackingList | null,
//     warehouse: Warehouse
// }


export interface Invoice extends Document, DocumentWeights {
    packingListDocument: InvoicePackingListDocument
    dispatched: boolean
    paid: boolean;
    customTotalGrossWeight: string;
    paidAt: string | null;
}

export interface InvoicePackingListDocument {
    id: number,
    docNumber: string,
    status: DocumentStatus
}

export enum DispatchType {
    "DIRECT" = "direct",
    "VIA_TRANSIT_HUB" = "via_transit_hub",
}

export enum DispatchStatus {
    "NOT_DISPATCHED" = "not_dispatched",
    "TO_TRANSIT_HUB" = "to_transit_hub",
    "TO_CUSTOMER" = "to_customer"
}

// export interface Invoice {
//     id: number;
//     docNumber: string;
//     dispatched: boolean;
//     paid: boolean;
//     sent: boolean;
//     paidAt: string | null;
//     customTotalGrossWeight: string;
//     subtotal: Amount;
//     vatFactor: string;
//     subtotalVat: Amount;
//     grandTotal: Amount;
//     totalNetWeight: string;
//     totalGrossWeight: string;
//     masterOrder: DocumentMasterOrder;
//     date: string;
//     sentAt: string;
//     createdAt: string;
//     updatedAt: string;
// }
