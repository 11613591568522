import React, {useMemo} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {propertyService} from "../../../../services/property/property.service";
import {columnFactory} from "./document.list.columns";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {PropertyDocument} from "../../../../models/property/property";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {Space, Typography} from "antd";

export const PropertyDocumentList: React.FC<ComponentPropsFromRoute> = ({requiredConfigs}) => {

    const t = useTranslate();

    const type: string = requiredConfigs?.type;

    const handler = useMemo(() => new SmartTableDataHandler(r => {
        r.addFilter('type', type);
        return propertyService.getDocuments(r)
    }), [type]);

    const columns = useMemo(() => columnFactory(t), [t])

    return (
        <Space size={[0, 0]} direction={"vertical"}>
            <SmartTable
                handler={handler}
                columns={columns}
                actions={[
                    {
                        icon: "download",
                        title: t("DOWNLOAD"),
                        onClick: async (item: PropertyDocument) => {
                            const {blob, filename} = await propertyService.downloadQtyChangePDF(item.id);
                            downloadBlobFile(blob, filename);
                        }
                    }
                ]}
            />
        </Space>
    )
}