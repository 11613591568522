import React, {useState} from "react";
import {QrCodeScanner} from "../../../../../common/qr-code-scanner/qr-code-scanner";
import {Space, Typography} from "antd";
import {useNavigate} from "react-router";
import {useOutletContext} from "react-router-dom";
import {ReviewContext} from "../../review.inventory-tasks.model";
import {UiInputWidgetPropertySearch} from "../../../../ui/input/widget/property-search/ui.input.widget.property-search";
import {propertyService} from "../../../../../../services/property/property.service";
import {LoadingSpin} from "../../../../../common/loading-spin/loading.spin";
import {notifications} from "../../../../../../services/notifications/notifications";

export interface ScanStepProps {
}

export const ScanStep: React.FC<ScanStepProps> = () => {

    const ctx = useOutletContext<ReviewContext>();

    const [manually, setManually] = useState<boolean>(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const complete = async (code: string) => {
        setLoading(true);

        try {
            const result = await propertyService.findByCode(code);
            if (result.length > 0) {
                completeWithID(result[0].id);
                return;
            }
        } catch (e) {
            notifications.message("error", "Loading error");
        }

        notifications.message("error", "Property " + code + " not found.");
        setLoading(false);
    }

    const completeWithID = (id: number) => {
        navigate('/property/review/inventory-task/' + ctx.task.id + '/review/' + id);
    }

    if (loading) {
        return <LoadingSpin />
    }

    if (manually) {
        return (
            <>
                <Typography.Title level={1} style={{marginBottom: 0}}>Please enter code</Typography.Title>
                <Typography.Paragraph>or <a onClick={() => setManually(false)}>Scan QR-code</a></Typography.Paragraph>
                <Space direction={"vertical"}>
                    <UiInputWidgetPropertySearch onChange={v => completeWithID(v as number)} />
                </Space>
            </>
        );
    }

    return (
        <>
            <Typography.Title level={1} style={{marginBottom: 0}}>Please scan QR-code</Typography.Title>
            <Typography.Paragraph>or <a onClick={() => setManually(true)}>Enter manually</a></Typography.Paragraph>
            <QrCodeScanner onDecode={v => complete(v)} />
        </>
    )
}