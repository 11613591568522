import React from 'react';
import {Breadcrumb} from "antd";
import {Link, useLocation, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {Translate, useTranslate} from "../../../hooks/translate.hook";
import { matchPath } from "react-router";
import {RouteConfig, routers} from "../../../routers/routers";

export const Breadcrumbs: React.FC = observer(() => {
    const {pageStore} = useRequiredStore(RootStoreContext);
    const t = useTranslate();
    const location = useLocation();
    const params = useParams();

    React.useEffect(() => {
        const configs = getAllCrumbConfigs(t, location, routers, params);
        const lastIndex = Object.keys(configs).pop();
        pageStore.setRoutes(configs)
        if (lastIndex) {
            pageStore.setFullWidth(configs[lastIndex].fullWith);
        }
    }, [location]);

    return (
        <Breadcrumb separator="/" className={"breadcrumbs"}>
            <Breadcrumb.Item key="home">
                <Link to="/">{t("MAIN_PAGE")}</Link>
            </Breadcrumb.Item>
            {Object.keys(pageStore.routes).map(key => (
                <Breadcrumb.Item key={key}>
                    <Link to={pageStore.routes[key].path}>{pageStore.routes[key].systemName ? t(pageStore.routes[key].systemName) : ""}</Link>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
});

const getAllCrumbConfigs = (t: Translate, l: {pathname: string}, routes: RouteConfig[], params: any, data: Record<string, {systemName: string, path: string, fullWith: boolean}> = {}) => {
    for (let route of routes) {
        if (matchPath({path: route.path, end: route.exact}, l.pathname)) {
            data[route.name] = {
                fullWith: route.fullWidth || false,
                systemName: route.systemName,
                path: Object
                    .keys(params)
                    .reduce((p, key) => p.replace(`:${key}`, params[key]), route.path),
            }
            if (route.children) {
                getAllCrumbConfigs(t, l, route.children, params, data);
            }
            return data;
        }
    }
    return data;
}