import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {propertyService} from "../../../../../services/property/property.service";
import {columnFactory} from "./review-list.property.view.columns";
import {Property, PropertyFile, PropertyInventoryTaskPropertyReview} from "../../../../../models/property/property";
import {notifications} from "../../../../../services/notifications/notifications";
import {PreviewImage} from "../../../../core/preview-image/preview-image";
import {SmartAction} from "../../../../common/smart-table/smart-table.model";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {isAllowed} from "../../../../../utils/helpers";

interface ReviewListPropertyViewProps {
    property: Property;
}

export const ReviewListPropertyView: React.FC<ReviewListPropertyViewProps> = ({property}) => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getPropertyReviews(property.id, r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const [previewPhoto, setPreviewPhoto] = useState<PropertyFile | null>(null);

    const remove = async (review: PropertyInventoryTaskPropertyReview) => {
        await propertyService.removeTaskPropertyReview(review.id)
        await handler.reloadItemsWithLastRequest();
        notifications.successfully();
    }

    const onPreview = async (review: PropertyInventoryTaskPropertyReview) => {
        setPreviewPhoto(review.propertyPhoto!);
    }

    const actions: SmartAction<PropertyInventoryTaskPropertyReview>[] = [
        {
            icon: "trash",
            title: t("REMOVE"),
            onClick: remove,
            isVisible: i => isAllowed(attributes, "property_management")
        },
        {
            icon: "eye",
            title: t("VIEW"),
            onClick: onPreview,
            isVisible: i => !!i.propertyPhoto
        },
    ]

    return (
        <>
            <SmartTable
                handler={handler}
                columns={columns}
                actions={actions}
            />
            {previewPhoto ? (<PreviewImage
                onClose={() => setPreviewPhoto(null)}
                title={previewPhoto.fileName}
                url={'/api/v3/property/attachment/' + previewPhoto.hash + '/' + previewPhoto.fileName}
            />) : null}
        </>
    )
}