import Index from "../components/index";
import { Login } from "../components/security/login/login";
import { MyPage } from "../components/users/mypage/my.page";
import { ReportsMainPage } from "../components/reports/main-page/reports.main.page";
import { UsersList } from "../components/users/users/list/users.list";
import { UserData } from "../components/users/users/data/user.data";
import { AgentsList } from "../components/agents/agents-list/agents.list";
import { AgentData } from "../components/agents/agent-data/agent.data";
import { OrdersCreate } from "../components/orders/orders-create/create/orders.create";
import { LanguagesList } from "../components/languages/languages/list/languages.list";
import { Declarations } from "../components/declarations/declarations";
import { Warehouses } from "../components/warehouses/warehouses/warehouses";
import { OrderData } from "../components/orders/order-data/order.data";
import { UserAccessesList } from "../components/users/users/accesses/list/user.accesses.list";
import { UserGroups } from "../components/users/users/groups/user.groups";
import { ArticleData } from "../components/articles/article-data/article.data";
import { Settings } from "../components/settings/settings";
import { Branches } from "../components/branches/list/branches";
import { OrdersCheckout } from "../components/orders/orders-create/checkout/orders.checkout";
import { WarehousesList } from "../components/warehouses/warehouses/list/warehouses.list";
import { ArticlesList } from "../components/articles/articles/list/articles.list";
import { ArticlesHistoryList } from "../components/articles/articles/history/articles.history.list";
import { ArticlesStockList } from "../components/articles/articles/stock/articles.stock.list";
import { ArticlesPricesList } from "../components/articles/articles/prices/article.prices.list";
import { ArticlesPricesListsList } from "../components/articles/articles/price-list/articles.price-lists.list";
import { DeclarationsList } from "../components/declarations/list/declarations.list";
import { DeclarationsProviders } from "../components/declarations/providers/declaration.providers.list";
import { DeclarationsCodes } from "../components/declarations/codes/declatations.codes.list";
import { DeclarationsDocumentsList } from "../components/declarations/documents/declarations.documents.list";
import { DeclarationsDeclarants } from "../components/declarations/declarants/declarations.declarants.list";
import { UsersGroupsList } from "../components/users/users/groups/list/users.groups.list";
import { UsersGroupsData } from "../components/users/users/groups/data/users.groups.data";
import { Planner } from "../components/planner/planner";
import { UserAssignRules } from "../components/users/users/assign-rules/user.assign.rules";
import { Announcements } from "../components/announcements/announcements";
import { InvoicesList } from "../components/documents/invoices/invoices-list/invoices.list";
import { InvoiceData } from "../components/documents/invoices/invoice-data/invoice.data";
import { NotFound } from "../components/not-found/not.found";
import { ShipmentsDropship } from "../components/shipments/shipments-dropship/shipments-dropship";
import { MissingItems } from "../components/missing-items/missing.items";
import { AgentDataAgreements } from "../components/agents/agent-data/agent-data-agreements/agent.data.agreements";
import { AgentDataAddresses } from "../components/agents/agent-data/agent-data-addresses/agent.data.addresses";
import { AgentDataAccounts } from "../components/agents/agent-data/agent-data-accounts/agent.data.accounts";
import { AgentDataCustomPrices } from "../components/agents/agent-data/agent-data-custom-prices/agent.data.custom.prices";
import { AgentDataOrders } from "../components/agents/agent-data/agent-data-orders/agent.data.orders";
import { AgentDataStatistics } from "../components/agents/agent-data/agent-data-stats/agent.data.statistics";
import { ForgotPassword } from "../components/security/forgot-password/forgot.password";
import { ForgotPasswordComplete } from "../components/security/forgot-password/complete/forgot.password.complete";
import { MergedInvoicesList } from "../components/documents/invoices/merged-invoices-list/merged.Invoices.list";
import { MergedInvoiceData } from "../components/documents/invoices/merged-invoice-data/merged.invoice.data";
import { SmartLogger } from "../components/smart-logger/smart.logger";
import { Agreements } from "../components/marketing/agreements/agreements-list/agreements";
import { OrdersCreateBody } from "../components/orders/orders-create/create/body/orders.create.body";
import { AgentDataThresholds } from "../components/agents/agent-data/agent-data-general/agent-data-thresholds/agent.data.thresholds";
import { BranchData } from "../components/branches/data/branch.data";
import { BranchDataBanks } from "../components/branches/data/branch-data-banks/branch.data.banks";
import { BranchDataWarehouses } from "../components/branches/data/branch-data-warehouses/branch.data.warehouses";
import { PackingLists } from "../components/documents/packing-lists/list/packing-lists.list";
import { ProformasList } from "../components/documents/proformas/list/proformas.list";
import { PackingListData } from "../components/documents/packing-lists/data/packing-list.data";
import { ProformaData } from "../components/documents/proformas/data/proforma.data";
import { BranchDataDepartments } from "../components/branches/data/branch-data-departments/branch.data.departments";
import { OrderDeliveryCreate } from "../components/orders/delivery/order.delivery.create";
import { ErrorsList } from "../components/errors/list/errors.list";
import { Errors } from "../components/errors/errors";
import { ArticlesLockedStock } from "../components/articles/articles/locked-stock/articles.locked-stock";
import { BranchDataDepartment } from "../components/branches/data/branch-data-departments/department/branch.data.department";
import { BranchDataMain } from "../components/branches/data/branch-data-main/branch.data.main";
import { BranchDataTaxRules } from "../components/branches/data/branch-data-tax-rules/branch.data.tax-rules";
import { BranchDataWarehouse } from "../components/branches/data/branch-data-warehouses/warehouse/branch.data.warehouse";
import { MyPageActivity } from "../components/users/mypage/my-page-activity/my.page.activity";
import { MyPageDetails } from "../components/users/mypage/my-page-details/my.page.details";
import { MyPageFiles } from "../components/users/mypage/my-page-files/my.page.files";
import {WikiMain} from "../components/wiki/main/main";
import {WikiEditPage} from "../components/wiki/page/edit/edit";
import {WikiViewPage} from "../components/wiki/page/view/view";
import {WikiDefaultPage} from "../components/wiki/page/default/default";
import {AgentDataClaims} from "../components/agents/agent-data/agent-data-claims/list/agent.data.claims";
import {ClaimsCreate} from "../components/claims/create/claims.create";
import {ClaimView} from "../components/claims/view/claim.view";
import {ClaimList} from "../components/claims/list/claim.list";
import {ShipmentsMain} from "../components/shipments/shipments-main/shipments-main";
import {ShipmentsWholesale} from "../components/shipments/shipments-wholesale/shipments-wholesale";
import {AgentDataGeneral} from "../components/agents/agent-data/agent-data-general/agent.data.general";
import {
    AgentDataWarehouses
} from "../components/agents/agent-data/agent-data-general/agent-data-warehouses/agent.data.warehouses";
import {
    AgentDataPrice
} from "../components/agents/agent-data/agent-data-general/agent-data-price/agent.data.price";
import {AgentDataAgent} from "../components/agents/agent-data/agent-data-general/agent-data-agent/agent.data.agent";
import {OrdersList} from "../components/orders/list/orders.list";
import {WholesaleOrdersList} from "../components/orders/list/wholesale/orders.list";
import {BranchDataCouriers} from "../components/branches/data/branch-data-couriers/branch.data.couriers";
import {BranchDataCourier} from "../components/branches/data/branch-data-couriers/courier/branch.data.courier";
import {
    BranchDataWarehousesPriorityGroups
} from "../components/branches/data/branch-data-warehouses/priority/branch.data.warehouse.priority-groups";
import {CreditInvoicesList} from "../components/documents/invoices/credit-invoices-list/credit-invoices.list";
import {CreditInvoiceData} from "../components/documents/invoices/credit-invoice-data/credit-invoice-data";
import {ReturnsList} from "../components/documents/returns/list/list";
import {ReturnData} from "../components/documents/returns/data/data";
import {ShipmentsView} from "../components/shipments/shipment-view/shipment-view";
import {DeclarationUpload} from "../components/declarations/upload/declaration.upload";
import {DeclarationView} from "../components/declarations/view/declaration.view";
import {AgentDataLogger} from "../components/agents/agent-data/agent-data-logger/agent.data.logger";
import {QueueStatus} from "../components/queue/status/queue.status";
import {BranchDataPrices} from "../components/branches/data/branch-data-prices/branch.data.prices";
import {OrdersListNew} from "../components/orders/list/wholesale/list-new/orders.list-new";
import {OrdersListBasic} from "../components/orders/list/wholesale/list/orders.list";
import {OrdersMain} from "../components/orders/main/orders.main";
import {SettingsActions} from "../components/settings/actions/actions";
import {Containers} from "../components/containers/containers";
import {ContainersList} from "../components/containers/list/containers.list";
import {ContainersView} from "../components/containers/view/containers.view";
import {ContainersOrders} from "../components/containers/view/tabs/orders/containers.orders";
import {ContainersItems} from "../components/containers/view/tabs/items/containers.items";
import {
    AgentDataTecOrder
} from "../components/agents/agent-data/agent-data-general/agent-data-tecorder/agent.data.tecorder";
import {
    AgentDataWebsite
} from "../components/agents/agent-data/agent-data-general/agent-data-website/agent.data.website";
import {
    AgentDataShipments
} from "../components/agents/agent-data/agent-data-general/agent-data-shipments/agent.data.shipments";
import {
    AgentDataDocuments
} from "../components/agents/agent-data/agent-data-general/agent-data-documents/agent.data.documents";
import {
    AgentDataInvoicesWrapper
} from "../components/agents/agent-data/agent-data-documents/agent.data.invoices.wrapper";
import {
    AgentDataInvoices
} from "../components/agents/agent-data/agent-data-documents/agent-data-invoices/agent.data.invoices";
import {
    AgentDataMergedInvoices
} from "../components/agents/agent-data/agent-data-documents/agent-data-merged-invoices/agent.data.merged.invoices";
import {AgentDataInvoicesNew} from "../components/agents/agent-data/agent-data-documents/new/agent.data.invoices.new";
import {
    AgentDataCreditInvoices
} from "../components/agents/agent-data/agent-data-documents/agent-data-credit-invoices/agent.data.credit-invoices";
import {
    AgentDataProformas
} from "../components/agents/agent-data/agent-data-documents/agent-data-proformas/agent.data.proformas";
import {ReportsReport} from "../components/reports/report/reports.report";
import {ReportsReportView} from "../components/reports/report/view/reports.report.view";
import {ReportsReportHistory} from "../components/reports/report/history/reports.report.history";
import {ArticlesStockReport} from "../components/articles/articles/stock-report/articles.stock-report";
import {AccountingMonthlyStatements} from "../components/accounting/monthly-statements/statements-list/statements-list";
import {
    AccountingMonthlyStatementsView
} from "../components/accounting/monthly-statements/statements-view/statements-view";
import {AccountingMain} from "../components/accounting/main/accounting-main";
import {Property} from "../components/property/property";
import {PropertyList} from "../components/property/property/list/list.property";
import {InventoryTasksList} from "../components/property/inventory-tasks/list/list.inventory-tasks";
import {InventoryTasksView} from "../components/property/inventory-tasks/view/view.inventory-task";
import {InventoryTasksReview} from "../components/property/inventory-tasks/review/review.inventory-tasks";
import {InventoryTasksPart} from "../components/property/inventory-tasks/part/part.inventory-tasks";
import {PropertyView} from "../components/property/property/view/view.property";
import {PropertyListFindByQrCode} from "../components/property/property/list/find-by-qr-code/list.find-by-qr-code";
import {PropertySettings} from "../components/property/settings/settings";
import {PropertyTypeList} from "../components/property/settings/type/list/list.type";
import {PropertyLocationList} from "../components/property/settings/location/list/list.location";
import {WebSiteSettings} from "../components/settings/website/website";
import {EditPageWebSiteSettings} from "../components/settings/website/page/edit/edit.page";
import {EditCategoriesWebSiteSettings} from "../components/settings/website/categories/edit/edit.categories";
import {ListPagesWebSiteSettings} from "../components/settings/website/page/list/list.pages";
import {WarehousesTaxes} from "../components/accounting/warehouses-taxes/warehouses.taxes";
import {createRouteConfig} from "./config-factory";
import {ScanStep} from "../components/property/inventory-tasks/review/steps/scan/scan.step";
import {ReviewStep} from "../components/property/inventory-tasks/review/steps/review/review.step";
import {
    ReviewTakePhotoStep
} from "../components/property/inventory-tasks/review/steps/review/take-photo/review.take-photo.step";
import {ReviewStatusStep} from "../components/property/inventory-tasks/review/steps/review/status/review.status.step";
import {
    InventoryTasksPartWriteOff
} from "../components/property/inventory-tasks/part-write-off/part-write-off.inventory-tasks";
import {MissingItemsPart} from "../components/missing-items/part/missing.items.part";
import {PlannerTasksList} from "../components/planner/tasks/list/tasks.list";
import {PlannerMyIssuesList} from "../components/planner/my-issues/list/my-issues.list";
import {PlannerTasksView} from "../components/planner/tasks/view/tasks.view";
import {BranchDataEmployees} from "../components/branches/data/branch-data-employees/branch.data.employees";
import {PlannerTasksCreateIssue} from "../components/planner/tasks/create-issue/tasks.create-issue";
import {PlannerTasksCreate} from "../components/planner/tasks/create/tasks.create";
import {PlannerTasksEdit} from "../components/planner/tasks/edit/tasks.edit";
import {SystemUserRole} from "../models/users/user/system.user-with-attributes";
import {PlannerTasksListInDepartment} from "../components/planner/tasks/list-in-department/tasks.list-in-department";
import {PropertyDocumentList} from "../components/property/document/list/document.list";
import {PlannerSettings} from "../components/planner/settings/settings";
import {PlannerSettingsTags} from "../components/planner/settings/tags/settings-tags";
import {CalendarHome} from "../components/calendar/calendar.home";
import {Calendar} from "../components/calendar/calendar/calendar.calendar";
import {Events} from "../components/calendar/events/calendar.events";
import {
    AgentDataBackorderList
} from "../components/agents/agent-data/agent-data-documents/agent-data-backorder/agent.data.backorder-list";
import {SettingsCommon} from "../components/settings/common/common";
import {PurchasingAlliances} from "../components/settings/common/purchasing-alliances/list/purchasing-alliances.list";
import {
    PurchasingAlliancesHome
} from "../components/settings/common/purchasing-alliances/home/purchasing-alliances.home";
import {DocumentsCodeFilesList} from "../components/declarations/document-code-files/documents-code-files.list";
import {ProductionRequestListWrapper} from "../components/production/list/request.list.wrapper";
import {ProductionRequestList} from "../components/production/list/request.list";
import {ProductionRequestView} from "../components/production/view/request.view";
import {BranchDataCountries} from "../components/branches/data/branch-data-countries/branch.data.countries";
import {
    AgentDataAddressesOptions
} from "../components/agents/agent-data/agent-data-general/agent-data-addresses/agent.data.addresses";
import {PropertyDocumentListWrapper} from "../components/property/document/list/document.list.wrapper";

export interface ComponentPropsFromRoute {
    requiredConfigs?: { [key: string]: any };
    routeChildren?: RouteConfig[];
    name: string;
}

export interface RouteConfig {
    systemName: string;
    path: string;
    component: any;
    authRequired?: boolean;
    viewInNavigationPanel?: boolean;
    attentionKey?: string;
    hidden?: boolean;
    iconName?: string;
    exact?: boolean;
    access?: string;
    role?: SystemUserRole;
    name: string;
    children?: RouteConfig[];
    parent?: RouteConfig;
    configData?: { [key: string]: any };
    fullWidth?: boolean;
    skipForFlatten?: boolean;
    redirectOnInitTo?: string;
}

export const routers: RouteConfig[] = [
    {
        systemName: "LOGIN.TITLE",
        path: "/login",
        component: Login,
        name: "login",
    },
    {
        systemName: "FORGOT_PASSWORD.TITLE",
        path: "/forgot-password",
        component: ForgotPassword,
        name: "forgot-password",
        exact: true,
    },
    {
        systemName: "FORGOT_PASSWORD.TITLE",
        path: "/forgot-password/complete",
        component: ForgotPasswordComplete,
        name: "forgot-password-complete",
        exact: true,
    },
    {
        systemName: "MY_PAGE.TITLE",
        path: "/my-page",
        component: MyPage,
        authRequired: true,
        viewInNavigationPanel: true,
        exact: false,
        name: "my_page",
        children: [
            {
                systemName: "MY_PAGE.TITLE",
                path: "/my-page",
                component: MyPageDetails,
                // access: "branch_list",
                exact: true,
                authRequired: true,
                name: "my-page-details",
            },
            // {
            //     systemName: "MY_PAGE.DEPARTMENT",
            //     path: "/my-page/department",
            //     component: MyPageDepartment,
            //     exact: true,
            //     authRequired: true,
            //     name: "my-page-department",
            // },
            {
                systemName: "MY_PAGE.FILES.TITLE",
                path: "/my-page/files",
                component: MyPageFiles,

                exact: true,
                authRequired: true,
                name: "my-page-files",
            },
            {
                systemName: "MY_PAGE.ACTIVITY",
                path: "/my-page/activity",
                component: MyPageActivity,

                exact: true,
                authRequired: true,
                name: "my-page-activity",
            },
            // {
            //     systemName: "MY_PAGE.SETTINGS",
            //     path: "/my-page/settings",
            //     component: MyPageSettings,
            //     // access: "branch_list",
            //     exact: true,
            //     authRequired: true,
            //     name: "my-page-settings",
            //     // getSystemName: params => Promise.resolve(params.branchId)
            // },
        ],
    },
    {
        systemName: "ERRORS.TITLE",
        path: "/errors",
        component: Errors,
        access: "warnings.management",
        authRequired: true,
        viewInNavigationPanel: true,
        iconName: "exclamation-triangle",
        name: "errors",
        // skipForFlatten: true,
        exact: false,
        redirectOnInitTo: "/errors/all",
        children: [
            // {
            //     systemName: "ALL",
            //     path: "/errors",
            //     component: ErrorsList,
            //     // access: "",
            //     exact: true,
            //     authRequired: true,
            //     name: "errors-documents",
            //     // skipForFlatten: true,
            //     // configData: {group: ""}
            // },
            {
                systemName: "ERRORS.ALL.TITLE",
                path: "/errors/:groupId",
                component: ErrorsList,
                // access: "",
                exact: true,
                authRequired: true,
                name: "errors-documents",
                // skipForFlatten: true,
                // configData: {group: "documents"}
            },
        ],
    },
    {
        systemName: "ORDERS.TITLE",
        path: "/orders",
        component: OrdersMain,
        access: "order_list",
        authRequired: true,
        exact: false,
        viewInNavigationPanel: true,
        attentionKey: "orders",
        iconName: "shopping-basket",
        name: "orders-list",
        children: [
            // {
            //     systemName: "ORDERS.LIST_GROUP.WHOLESALE.TITLE",
            //     path: "/orders/wholesale",
            //     component: WholesaleOrdersList,
            //     name: "orders-list-wholesale",
            //     authRequired: true,
            //     exact: true,
            // },
            // {
            //     systemName: "ORDERS.LIST_GROUP.WHOLESALE.TITLE",
            //     path: "/orders/wholesale",
            //     component: WholesaleOrdersList,
            //     name: "orders-list-wholesale",
            //     authRequired: true,
            //     exact: true,
            // },
            // {
            //     systemName: "ORDERS.LIST_GROUP.RETAIL.TITLE",
            //     path: "/orders/retail",
            //     component: RetailOrdersList,
            //     name: "orders-list-retail",
            //     authRequired: true,
            //     exact: true,
            // },
            {
                systemName: "ORDERS.LIST_GROUP.WHOLESALE.TITLE",
                path: "/orders",
                component: OrdersList,
                name: "orders-list-wholesale",
                authRequired: true,
                exact: true,
            },
            {
                systemName: "ORDERS.LIST_GROUP.WHOLESALE.TITLE",
                path: "/orders/list",
                component: WholesaleOrdersList,
                name: "orders-list-wholesale-list",
                authRequired: true,
                exact: false,
                children: [
                    {
                        systemName: "ORDERS.LIST",
                        path: "/orders/list",
                        component: OrdersListBasic,
                        name: "orders-list-wholesale-list-basic",
                        authRequired: true,
                        exact: true,
                    },
                    {
                        systemName: "ORDERS.NEW",
                        path: "/orders/list/new",
                        component: OrdersListNew,
                        name: "orders-list-wholesale-list-new",
                        authRequired: true,
                        exact: true,
                        //access: "document_sale_create"
                    }
                ]
            },
            {
                systemName: "ORDERS.DELIVERY.TITLE",
                path: "/orders/delivery",
                component: OrderDeliveryCreate,
                access: "order_list",
                authRequired: true,
                viewInNavigationPanel: false,
                name: "orders_delivery",
            },
            {
                systemName: "ORDERS.DATA.TITLE",
                path: "/orders/:orderId",
                component: OrderData,
                access: "order_view",
                authRequired: true,
                exact: true,
                name: "order",
            }
        ]
    },
    {
        systemName: "SHIPMENTS.SHIPMENT.TITLE",
        path: "/shipment/:id",
        component: ShipmentsView,
        authRequired: true,
        access: "shipment_management",
        exact: true,
        name: "shipment_view"
    },
    {
        systemName: "SHIPMENTS.TITLE",
        path: "/shipments",
        component: ShipmentsMain,
        authRequired: true,
        access: "shipment_management",
        viewInNavigationPanel: true,
        attentionKey: "shipments",
        iconName: "truck",
        exact: false,
        name: "shipments",
        children: [
            {
                systemName: "SHIPMENTS.DROPSHIP.TITLE",
                path: "/shipments/dropship",
                component: ShipmentsDropship,
                authRequired: true,
                exact: false,
                name: "shipments-dropship"
            },
            // {
            //     systemName: "SHIPMENTS.RETAIL.TITLE",
            //     path: "/shipments/retail",
            //     component: ShipmentsRetail,
            //     authRequired: true,
            //     exact: false,
            //     name: "shipments-retail"
            // },
            {
                systemName: "SHIPMENTS.WHOLESALE.TITLE",
                path: "/shipments/wholesale",
                component: ShipmentsWholesale,
                authRequired: true,
                exact: false,
                name: "shipments-wholesale"
            }
        ]
    },
    {
        systemName: "CLAIMS.VIEW",
        path: "/claims/:id",
        component: ClaimView,
        authRequired: true,
        access: "claims",
        exact: false,
        name: "claims_view",
        viewInNavigationPanel: false
    },
    {
        systemName: "CLAIMS.TITLE",
        path: "/claims",
        component: ClaimList,
        authRequired: true,
        access: "claims",
        viewInNavigationPanel: true,
        attentionKey: "claims",
        iconName: "sad-cry",
        exact: false,
        name: "claims_list",
    },
    {
        systemName: "INVOICES.TITLE",
        path: "/invoices",
        component: InvoicesList,
        access: "order_list",
        authRequired: true,
        viewInNavigationPanel: false,
        // exact: true,
        name: "invoices",
    },
    {
        systemName: "INVOICES.MERGED.TITLE",
        path: "/merged-invoices",
        component: MergedInvoicesList,
        access: "order_list",
        authRequired: true,
        viewInNavigationPanel: false,
        name: "merged_invoices",
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/invoices/:invoiceId",
        component: InvoiceData,
        access: "order_view",
        authRequired: true,
        exact: true,
        name: "invoice",
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/merged-invoices/:mergedInvoiceId",
        component: MergedInvoiceData,
        access: "order_view",
        authRequired: true,
        exact: true,
        name: "invoice",
    },
    {
        systemName: "INVOICES.CREDIT.TITLE",
        path: "/credit-invoices",
        component: CreditInvoicesList,
        access: "order_list",
        authRequired: true,
        viewInNavigationPanel: false,
        name: "credit_invoices_list",
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/credit-invoices/:id",
        component: CreditInvoiceData,
        access: "order_view",
        authRequired: true,
        exact: true,
        name: "credit_invoice_details",
    },
    {
        systemName: "DOCUMENTS.RETAIL.RETURN.LIST.TITLE",
        path: "/returns",
        component: ReturnsList,
        access: "order_list",
        authRequired: true,
        viewInNavigationPanel: false,
        name: "document_return_list",
    },
    {
        systemName: "DOCUMENTS.RETAIL.RETURN.TITLE",
        path: "/returns/:id",
        component: ReturnData,
        access: "order_view",
        authRequired: true,
        exact: true,
        name: "document_return_details",
    },
    {
        systemName: "PACKING_LISTS.TITLE",
        path: "/packing-lists",
        component: PackingLists,
        access: "",
        authRequired: true,
        exact: true,
        name: "packing_lists",
    },
    {
        systemName: "PACKING_LIST",
        path: "/packing-lists/:packingListId",
        component: PackingListData,
        access: "",
        authRequired: true,
        exact: true,
        name: "packing_list",
    },
    {
        systemName: "PROFORMAS.TITLE",
        path: "/proformas",
        component: ProformasList,
        access: "",
        authRequired: true,
        exact: true,
        name: "proformas",
    },
    {
        systemName: "PROFORMA.TITLE",
        path: "/proformas/:proformaId",
        component: ProformaData,
        access: "",
        authRequired: true,
        exact: true,
        name: "proforma",
    },
    {
        systemName: "REPORTS.TITLE",
        path: "/reports",
        component: ReportsMainPage,
        authRequired: true,
        viewInNavigationPanel: true,
        iconName: "chart-line",
        name: "reports",
    },
    {
        systemName: "REPORTS.VIEW_REPORT",
        path: "/reports/:name",
        component: ReportsReport,
        authRequired: true,
        viewInNavigationPanel: false,
        name: "reports-report",
        exact: false,
        fullWidth: true,
        children: [
            {
                name: "reports-report-view",
                systemName: "REPORTS.REPORT.VIEW_REPORT",
                path: "/reports/:name",
                component: ReportsReportView,
                exact: true,
                fullWidth: true
            },
            {
                name: "reports-report-view",
                systemName: "REPORTS.REPORT.HISTORY",
                path: "/reports/:name/history",
                component: ReportsReportHistory,
                exact: true,
                fullWidth: true
            },
        ]
    },
    {
        systemName: "WAREHOUSES.TITLE",
        path: "/warehouses",
        component: Warehouses,
        authRequired: true,
        access: "warehouse_list",
        viewInNavigationPanel: true,
        iconName: "warehouse",
        exact: false,
        name: "warehouses",
        children: [
            {
                systemName: "WAREHOUSES.TITLE",
                path: "/warehouses",
                component: WarehousesList,
                access: "warehouse_list",
                authRequired: true,
                exact: true,
                name: "warehouses",
            }
        ],
    },
    {
        systemName: "ARTICLES.TITLE",
        path: "/articles",
        component: ArticlesList,
        authRequired: true,
        access: "article_list",
        viewInNavigationPanel: true,
        iconName: "cube",
        exact: true,
        name: "articles",
    },
    {
        systemName: "ARTICLES.HISTORY",
        path: "/articles/history",
        component: ArticlesHistoryList,
        access: "article_history",
        authRequired: true,
        exact: true,
        name: "article_history",
    },
    {
        systemName: "ARTICLES.WAREHOUSES.TITLE",
        path: "/articles/stock",
        component: ArticlesStockList,
        access: "article_stock",
        authRequired: true,
        exact: true,
        configData: { warehouseTypes: ["retail", "customs"] },
        name: "article_stock",
    },
    {
        systemName: "ARTICLES.STOCK-REPORT.TITLE",
        path: "/articles/stock-report",
        component: ArticlesStockReport,
        access: "article_history",
        authRequired: true,
        exact: true,
        name: "article_stock_report",
    },
    {
        systemName: "ARTICLES.CONS_WAREHOUSES.TITLE",
        path: "/articles/consignation-stock",
        component: ArticlesStockList,
        access: "article_stock",
        authRequired: true,
        exact: true,
        configData: { warehouseTypes: ["consignation"] },
        name: "article_stock_consignation",
    },
    {
        systemName: "ARTICLES.PRICES.TITLE",
        path: "/articles/prices",
        component: ArticlesPricesList,
        access: "article_prices",
        authRequired: true,
        exact: true,
        name: "article_prices",
    },
    {
        systemName: "PRICE_LISTS",
        path: "/articles/price-lists",
        component: ArticlesPricesListsList,
        access: "price_list",
        authRequired: true,
        exact: true,
        name: "article_price_list",
    },
    {
        systemName: "ARTICLES.LOCKED_STOCK",
        path: "/articles/locked-stock",
        component: ArticlesLockedStock,
        access: "",
        authRequired: true,
        exact: true,
        name: "articles_locked_stock",
    },
    {
        systemName: "ARTICLES.TITLE_SINGLE",
        path: "/articles/:articleId",
        component: ArticleData,
        access: "article_view",
        authRequired: true,
        viewInNavigationPanel: false,
        exact: true,
        name: "article",
    },
    {
        systemName: "USERS.TITLE",
        path: "/users",
        component: UsersList,
        access: "user_list",
        authRequired: true,
        viewInNavigationPanel: true,
        iconName: "users",
        name: "users",
    },
    {
        systemName: "USERS.ACCESSES.TITLE",
        path: "/users/accesses",
        access: "firewall_management",
        component: UserAccessesList,
        authRequired: true,
        name: "accesses",
    },
    {
        systemName: "USERS.ASSIGN_RULES.TITLE",
        path: "/users/groups-assign-rule",
        access: "firewall_management",
        component: UserAssignRules,
        authRequired: true,
        name: "assign_rules",
    },
    {
        systemName: "USERS.GROUPS.TITLE",
        path: "/users/groups",
        component: UserGroups,
        access: "firewall_management",
        authRequired: true,
        exact: false,
        name: "user_groups",
        skipForFlatten: true,
        children: [
            {
                systemName: "USERS.GROUPS.TITLE",
                path: "/users/groups",
                component: UsersGroupsList,
                access: "firewall_management",
                authRequired: true,
                exact: true,
                name: "user_groups",
            },
            {
                systemName: "USERS.GROUPS.DATA.TITLE",
                path: "/users/groups/:groupsId",
                component: UsersGroupsData,
                access: "firewall_management",
                authRequired: true,
                exact: true,
                name: "user_group",
            },
        ],
    },
    {
        systemName: "USER.DATA.TITLE",
        path: "/users/:userId",
        component: UserData,
        access: "user_management",
        authRequired: true,
        name: "user",
    },
    {
        systemName: "CLIENTS.TITLE",
        path: "/clients",
        component: AgentsList,
        access: "agent_list",
        authRequired: true,
        viewInNavigationPanel: true,
        attentionKey: "clients",
        iconName: "address-card",
        name: "clients",
    },
    {
        systemName: "CLIENTS.DATA.TITLE",
        path: "/clients/:number",
        access: "agent_view",
        component: AgentData,
        authRequired: false,
        name: "client",
        exact: false,
        skipForFlatten: true,
        children: [
            {
                systemName: "CLIENT.DOCUMENTS.TITLE",
                path: "/clients/:number/documents",
                component: AgentDataInvoicesWrapper,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_invoices",
                children: [
                    {
                        systemName: "ORDERS.TITLE",
                        path: "/clients/:number/documents",
                        component: AgentDataOrders,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_orders",
                    },
                    {
                        systemName: "PROFORMAS.TITLE",
                        path: "/clients/:number/documents/proformas",
                        component: AgentDataProformas,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_proformas",
                    },
                    {
                        systemName: "INVOICES.TITLE",
                        path: "/clients/:number/documents/invoices",
                        component: AgentDataInvoices,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_invoices",
                    },
                    {
                        systemName: "INVOICES.MERGED.TITLE",
                        path: "/clients/:number/documents/merged-invoices",
                        component: AgentDataMergedInvoices,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_merged_invoices",
                    },
                    {
                        systemName: "INVOICES.CREDIT.TITLE",
                        path: "/clients/:number/documents/credit-invoices",
                        component: AgentDataCreditInvoices,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_credit_invoices",
                    },
                    {
                        systemName: "BACKORDER.TITLE",
                        path: "/clients/:number/documents/backorder",
                        component: AgentDataBackorderList,
                        authRequired: true,
                        access: "order_backorder",
                        exact: true,
                        name: "agent_backorder",
                    },
                    {
                        systemName: "ORDERS.CREATE_SALE_DOCUMENT",
                        path: "/clients/:number/documents/new-sale-document",
                        component: AgentDataInvoicesNew,
                        authRequired: true,
                        access: "agent_view",
                        exact: true,
                        name: "agent_invoices_new",
                        hidden: true
                    }
                ],
            },
            {
                systemName: "AGREEMENT.TITLE",
                path: "/clients/:number/agreements",
                component: AgentDataAgreements,
                authRequired: true,
                access: "agent_view",
                exact: true,
                name: "agent_agreements",
            },
            {
                systemName: "ADDRESSES.TITLE",
                path: "/clients/:number/addresses",
                component: AgentDataAddresses,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_addresses",
            },
            {
                systemName: "ACCOUNTS.TITLE",
                path: "/clients/:number/accounts",
                component: AgentDataAccounts,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_accounts",
            },
            {
                systemName: "ORDERS.CREATE.TITLE",
                path: "/clients/:number/cart",
                component: OrdersCreate,
                authRequired: true,
                access: "order_management",
                exact: false,
                name: "create_order",
                hidden: true,
                children: [
                    {
                        systemName: "ORDERS.CREATE_BASIC.TITLE",
                        path: "/clients/:number/cart",
                        component: OrdersCreateBody,
                        access: "order_management",
                        authRequired: true,
                        exact: true,
                        name: "create_order",
                        configData: {
                            type: 'regular'
                        }
                    },
                    {
                        systemName: "ORDERS.CREATE_RESERVATION.TITLE",
                        path: "/clients/:number/cart/reservation",
                        component: OrdersCreateBody,
                        access: "order_create_reservation",
                        authRequired: true,
                        exact: true,
                        name: "order_create_backorder",
                        configData: {
                            type: 'reservation'
                        }
                    },
                    {
                        systemName: "ORDERS.CREATE_BACKORDER.TITLE",
                        path: "/clients/:number/cart/backorder",
                        component: OrdersCreateBody,
                        access: "order_create_backorder",
                        authRequired: true,
                        exact: true,
                        name: "create_backorder",
                        configData: {
                            type: 'backorder'
                        }
                    },
                    {
                        systemName: "ORDERS.CHECKOUT.TITLE",
                        path: "/clients/:number/cart/checkout",
                        component: OrdersCheckout,
                        access: "order_management",
                        authRequired: true,
                        exact: true,
                        name: "apply_order",
                        hidden: true
                    },
                ],
            },
            {
                systemName: "CLAIMS.CREATE.TITLE",
                path: "/clients/:number/claims/new",
                component: ClaimsCreate,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_claims_new",
                hidden: true
            },
            {
                systemName: "CLAIMS.TITLE",
                path: "/clients/:number/claims",
                component: AgentDataClaims,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_claims",
            },
            {
                systemName: "MISSING_ITEMS",
                path: "/clients/:number/missing-items",
                component: MissingItems,
                authRequired: true,
                access: "order_management",
                exact: false,
                name: "missing_items",
                children: [
                    {
                        systemName: "Active",
                        path: "/clients/:number/missing-items",
                        component: MissingItemsPart,
                        name: "missing_items_shown",
                        exact: true,
                        configData: {active: true}
                    },
                    {
                        systemName: "Hidden",
                        path: "/clients/:number/missing-items/hidden",
                        component: MissingItemsPart,
                        name: "missing_items_hidden",
                        exact: true,
                        configData: {active: false}
                    },
                ]
            },
            {
                systemName: "CLIENT.CUSTOM_PRICE.TITLE",
                path: "/clients/:number/custom-prices",
                component: AgentDataCustomPrices,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_custom_prices",
            },
            {
                systemName: "CLIENT.STATISTICS.TITLE",
                path: "/clients/:number/statistics",
                component: AgentDataStatistics,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_statistics",
            },
            {
                systemName: "LOGGER.TITLE",
                path: "/clients/:number/logger",
                component: AgentDataLogger,
                authRequired: true,
                access: "smartlogger.list",
                exact: false,
                name: "agent_logger",
            },
            {
                systemName: "CLIENT.SETTINGS.TITLE",
                path: "/clients/:number/settings",
                component: AgentDataGeneral,
                authRequired: true,
                access: "agent_view",
                exact: false,
                name: "agent_general",
                children: [
                    {
                        systemName: "CLIENT.AGENT.TITLE",
                        path: "/clients/:number/settings",
                        component: AgentDataAgent,
                        authRequired: true,
                        access: "",
                        exact: true,
                        name: "agent_agent",
                    },
                    {
                        systemName: "CLIENT.PRICE.TITLE",
                        path: "/clients/:number/settings/price",
                        component: AgentDataPrice,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_price",
                    },
                    {
                        systemName: "CLIENT.WAREHOUSES.TITLE",
                        path: "/clients/:number/settings/warehouses",
                        component: AgentDataWarehouses,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_warehouses",
                    },
                    {
                        systemName: "ADDRESSES.TITLE",
                        path: "/clients/:number/settings/addresses",
                        component: AgentDataAddressesOptions,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_addresses",
                    },
                    {
                        systemName: "CLIENT.DOCUMENTS.TITLE",
                        path: "/clients/:number/settings/documents",
                        component: AgentDataDocuments,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_warehouses",
                    },
                    {
                        systemName: "CLIENT.SHIPMENTS.TITLE",
                        path: "/clients/:number/settings/shipments",
                        component: AgentDataShipments,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_shipments",
                    },
                    {
                        systemName: "CLIENT.WEBSITE.TITLE",
                        path: "/clients/:number/settings/website",
                        component: AgentDataWebsite,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_warehouses",
                    },
                    {
                        systemName: "CLIENT.TEC-ORDER.TITLE",
                        path: "/clients/:number/settings/tec-order",
                        component: AgentDataTecOrder,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_tecorder",
                    },
                    {
                        systemName: "CLIENT.THRESHOLDS.TITLE",
                        path: "/clients/:number/settings/thresholds",
                        component: AgentDataThresholds,
                        authRequired: true,
                        access: "",
                        exact: false,
                        name: "agent_thresholds",
                    }
                ]
            },
        ],
    },
    {
        systemName: "DECLARATION.TITLE",
        path: "/declaration/:id",
        component: DeclarationView,
        authRequired: true,
        access: "declaration_list",
        exact: true,
        name: "declaration_view"
    },
    {
        systemName: "DECLARATIONS.TITLE",
        path: "/declarations",
        component: Declarations,
        authRequired: true,
        access: "declaration_list",
        viewInNavigationPanel: true,
        attentionKey: "declarations",
        iconName: "balance-scale",
        exact: false,
        name: "declaration_list",
        children: [
            {
                systemName: "DECLARATIONS.UPLOAD.TITLE",
                path: "/declarations/upload",
                component: DeclarationUpload,
                access: "declaration_list",
                authRequired: true,
                exact: true,
                hidden: true,
                name: "declaration_upload",
            },
            {
                systemName: "DECLARATIONS.TITLE",
                path: "/declarations",
                component: DeclarationsList,
                access: "declaration_list",
                authRequired: true,
                exact: true,
                name: "declaration_list",
                fullWidth: true
            },
            {
                systemName: "PROVIDERS.TITLE",
                path: "/declarations/providers",
                component: DeclarationsProviders,
                access: "declaration_provider_list",
                authRequired: true,
                exact: true,
                name: "declaration_provider_list",
                fullWidth: true
            },
            {
                systemName: "DECLARATIONS.CODE_SPECIFICATION",
                path: "/declarations/codes",
                component: DeclarationsCodes,
                access: "declaration_code_list",
                authRequired: true,
                exact: true,
                name: "declaration_code_list",
                fullWidth: true
            },
            {
                systemName: "DECLARATIONS.ADD_CODE_DOCUMENTS",
                path: "/declarations/documents",
                component: DeclarationsDocumentsList,
                access: "declaration_code_documents_list",
                authRequired: true,
                exact: true,
                name: "declaration_code_documents_list",
                fullWidth: true
            },
            {
                systemName: "DECLARATIONS.DECLARANTS",
                path: "/declarations/declarants",
                access: "declaration_declarants_list",
                component: DeclarationsDeclarants,
                authRequired: true,
                exact: true,
                name: "declaration_declarants_list",
                fullWidth: true
            },
            {
                systemName: "DECLARATIONS.DOCUMENTS_CODE_FILES",
                path: "/declarations/documents-code-files",
                access: "declaration_declarants_list",
                component: DocumentsCodeFilesList,
                authRequired: true,
                exact: true,
                name: "declaration_documents_code_files",
                fullWidth: true
            }
        ],
    },
    {
        systemName: "CONTAINERS.TITLE",
        path: "/containers",
        component: Containers,
        authRequired: true,
        access: "containers_management",
        viewInNavigationPanel: true,
        attentionKey: "containers",
        iconName: "ship",
        exact: false,
        name: "containers_list",
        children: [
            {
                systemName: "CONTAINERS.VIEW.TITLE",
                path: "/containers/:id",
                component: ContainersView,
                authRequired: true,
                exact: false,
                name: "container_view",
                children: [
                    {
                        systemName: "CONTAINERS.CONTAINER.ITEMS",
                        path: "/containers/:id",
                        component: ContainersItems,
                        authRequired: true,
                        exact: true,
                        name: "container_view_items"
                    },
                    {
                        systemName: "CONTAINERS.CONTAINER.ORDERS",
                        path: "/containers/:id/orders",
                        component: ContainersOrders,
                        exact: true,
                        name: "container_view_orders"
                    }
                ]
            },
            {
                systemName: "CONTAINERS.TITLE",
                path: "/containers",
                component: ContainersList,
                //access: "containers_list",
                authRequired: true,
                exact: true,
                name: "containers_list",
            },
        ]
    },
    {
        systemName: "PRODUCTION_REQUEST.LIST.TITLE",
        name: 'production-requests',
        path: "/production",
        component: ProductionRequestListWrapper,
        authRequired: true,
        viewInNavigationPanel: true,
        attentionKey: "prodRequests",
        iconName: "industry",
        exact: false,
        access: 'pa_management',
        children: [
            {
                systemName: "PRODUCTION_REQUEST.LIST.TYPE.ORDER.TITLE",
                name: 'production-requests-orders',
                path: "/production",
                component: ProductionRequestList,
                configData: {
                    type: "order",
                }
            },
            {
                systemName: "PRODUCTION_REQUEST.LIST.TYPE.OFFER.TITLE",
                name: 'production-requests-offers',
                path: "/production/offer",
                component: ProductionRequestList,
                configData: {
                    type: "offer",
                }
            }
        ]
    },
    {
        systemName: "PRODUCTION_REQUEST.VIEW.TITLE",
        name: 'production-application-view',
        path: "/production/application/:id",
        component: ProductionRequestView,
        authRequired: true,
        viewInNavigationPanel: false,
        exact: false,
        access: 'pa_management'
    },
    {
        systemName: "ACCOUNTING.MAIN.TITLE",
        path: "/accounting",
        component: AccountingMain,
        authRequired: true,
        exact: false,
        access: "accountants",
        viewInNavigationPanel: true,
        name: "accounting-monthly-statements",
        iconName: "euro-sign",
        children: [
            {
                systemName: "REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.TITLE",
                path: "/accounting/monthly-statements",
                component: AccountingMonthlyStatements,
                access: "accountants",
                name: "accounting-monthly-statements",
                exact: true
            },
            {
                systemName: "REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.VIEW.TITLE",
                path: "/accounting/monthly-statements/:id",
                component: AccountingMonthlyStatementsView,
                name: "accounting-monthly-statements-view",
                exact: true,
                fullWidth: true
            },
            {
                systemName: "WAREHOUSES.TAXES.TITLE",
                path: "/accounting/tax-rules",
                component: WarehousesTaxes,
                access: "warehouse_tax_rules_list",
                authRequired: true,
                exact: true,
                name: "warehouses_tax_rules",
            },
        ]
    },
    {
        systemName: "PROPERTY.INVENTORY_TASK.REVIEW.TITLE",
        path: "/property/review/inventory-task/:id",
        component: InventoryTasksReview,
        authRequired: true,
        viewInNavigationPanel: false,
        skipForFlatten: true,
        access: 'property_allow_inventory',
        name: "property-review",
        children: [
            {
                systemName: "PROPERTY.INVENTORY_TASK.REVIEW.TITLE",
                path: "/property/review/inventory-task/:id",
                component: ScanStep,
                name: "property-review-scan",
                exact: true,
            },
            {
                systemName: "PROPERTY.INVENTORY_TASK.REVIEW.TITLE",
                path: "/property/review/inventory-task/:id/review/:code",
                component: ReviewStep,
                name: "property-review-review",
                exact: true,
                children: [
                    {
                        systemName: "PROPERTY.INVENTORY_TASK.REVIEW.TITLE",
                        path: "/property/review/inventory-task/:id/review/:code/take-photo",
                        component: ReviewTakePhotoStep,
                        name: "property-review-review",
                        exact: true
                    },
                    {
                        systemName: "PROPERTY.INVENTORY_TASK.REVIEW.TITLE",
                        path: "/property/review/inventory-task/:id/review/:code/status",
                        component: ReviewStatusStep,
                        name: "property-review-review",
                        exact: true
                    },
                ]
            },
        ]
    },
    {
        systemName: "PROPERTY.MAIN.TITLE",
        path: "/property",
        component: Property,
        authRequired: true,
        exact: false,
        fullWidth: true,
        access: "property_view",
        viewInNavigationPanel: true,
        attentionKey: "inventory",
        name: "property",
        iconName: "chair",
        children: [
            {
                systemName: "PROPERTY.PROPERTY_LIST.TITLE",
                path: "/property",
                component: PropertyList,
                name: "property-list",
                exact: true,
                fullWidth: true
            },
            {
                systemName: "PROPERTY.PROPERTY_LIST.TITLE",
                path: "/property/find-by-qr-code",
                component: PropertyListFindByQrCode,
                name: "property-find-by-qr-code",
                exact: true,
                fullWidth: true,
                hidden: true
            },
            {
                systemName: "PROPERTY.INVENTORY_TASKS_LIST.TITLE",
                path: "/property/inventory-tasks",
                component: InventoryTasksList,
                name: "property-inventory-tasks-list",
                exact: true,
                fullWidth: false,
                access: 'property_allow_inventory',
            },
            {
                systemName: "PROPERTY.PROPERTY_SETTINGS.TITLE",
                path: "/property/settings",
                component: PropertySettings,
                name: "property-settings",
                exact: false,
                fullWidth: false,
                access: 'property_management',
                children: [
                    {
                        systemName: "PROPERTY.PROPERTY_TYPES_LIST.TITLE",
                        path: "/property/settings/types",
                        component: PropertyTypeList,
                        name: "property-types-list",
                        exact: true,
                        fullWidth: false
                    },
                    {
                        systemName: "PROPERTY.PROPERTY_LOCATIONS_LIST.TITLE",
                        path: "/property/settings/locations",
                        component: PropertyLocationList,
                        name: "property-locations-list",
                        exact: true,
                        fullWidth: false
                    }
                ]
            },
            {
                systemName: "PROPERTY.PROPERTY_DOCUMENTS.TITLE",
                path: "/property/documents",
                component: PropertyDocumentListWrapper,
                name: "property-documents",
                exact: false,
                fullWidth: false,
                access: 'property_management',
                children: [
                    {
                        systemName: "PROPERTY.PROPERTY_DOCUMENTS.TYPE.INVENTORY_INCREASE.TITLE",
                        path: "/property/documents/inventory-decrease",
                        component: PropertyDocumentList,
                        name: "property-documents-inventory-decrease",
                        configData: {
                            type: 'inventory_decrease'
                        }
                    },
                    {
                        systemName: "PROPERTY.PROPERTY_DOCUMENTS.TYPE.DECREASE.TITLE",
                        path: "/property/documents/decrease",
                        component: PropertyDocumentList,
                        name: "property-documents-decrease",
                        configData: {
                            type: 'decrease'
                        }
                    },
                    {
                        systemName: "PROPERTY.PROPERTY_DOCUMENTS.TYPE.INCREASE.TITLE",
                        path: "/property/documents/increase",
                        component: PropertyDocumentList,
                        name: "property-documents-increase",
                        configData: {
                            type: 'increase'
                        }
                    }
                ]
            },
            {
                systemName: "PROPERTY.INVENTORY_TASK_PART.TITLE",
                path: "/property/inventory-tasks/part/:id",
                component: InventoryTasksPart,
                name: "property-inventory-tasks-part-view",
                exact: true,
                fullWidth: true,
                hidden: true
            },
            {
                systemName: "PROPERTY.INVENTORY_TASK_PART_WRITE_OFF.TITLE",
                path: "/property/inventory-tasks/part/:id/write-off",
                component: InventoryTasksPartWriteOff,
                name: "property-inventory-tasks-part-write-off-view",
                exact: true,
                fullWidth: true,
                hidden: true
            },
            {
                systemName: "PROPERTY.INVENTORY_TASK.TITLE",
                path: "/property/inventory-tasks/:id",
                component: InventoryTasksView,
                name: "property-inventory-tasks-view",
                exact: true,
                fullWidth: false,
                hidden: true
            },
            {
                systemName: "PROPERTY.VIEW.TITLE",
                path: "/property/:id",
                component: PropertyView,
                name: "property-view",
                exact: true,
                fullWidth: false,
                hidden: true
            },
        ]
    },
    {
        systemName: "PLANNER.TITLE",
        path: "/planner",
        component: Planner,
        authRequired: true,
        viewInNavigationPanel: true,
        attentionKey: "planner",
        iconName: "list-check",
        name: "planner",
        children: [
            {
                systemName: "PLANNER.TASKS.CREATE.TITLE",
                path: "/planner/tasks/create",
                component: PlannerTasksCreate,
                name: "planner-tasks-create",
                exact: true,
                hidden: true
            },
            {
                systemName: "PLANNER.TASKS.CREATE-ISSUE.TITLE",
                path: "/planner/tasks/issue-create",
                component: PlannerTasksCreateIssue,
                name: "planner-tasks-create-issue",
                exact: true,
                hidden: true
            },
            {
                systemName: "PLANNER.TASKS.EDIT.TITLE",
                path: "/planner/tasks/:id/edit",
                component: PlannerTasksEdit,
                name: "planner-tasks-edit",
                exact: true,
                hidden: true
            },
            {
                systemName: "PLANNER.TASKS.TITLE",
                path: "/planner/tasks/:id",
                component: PlannerTasksView,
                name: "planner-tasks-view",
                exact: true,
                hidden: true
            },
            {
                systemName: "PLANNER.TASKS_IN_DEPARTMENT.TITLE",
                path: "/planner/tasks-in-department",
                component: PlannerTasksListInDepartment,
                name: "planner-tasks",
                exact: true,
                role: "superior"
            },
            {
                systemName: "PLANNER.TASKS.TITLE",
                path: "/planner/tasks",
                component: PlannerTasksList,
                name: "planner-tasks",
                exact: true,
            },
            {
                systemName: "PLANNER.MY-ISSUES.TITLE",
                path: "/planner/my-issues",
                component: PlannerMyIssuesList,
                name: "planner-my_issues",
                exact: true,
            },
            {
                systemName: "PLANNER.SETTINGS.TITLE",
                path: "/planner/settings",
                component: PlannerSettings,
                name: "planner-settings",
                exact: true,
                role: "superior",
                children: [
                    {
                        systemName: "PLANNER.SETTINGS.TAGS.TITLE",
                        path: "/planner/settings/tags",
                        component: PlannerSettingsTags,
                        name: "planner-settings-tags",
                        exact: true,
                    },
                ]
            },
        ]
    },
    {
        systemName: "CALENDAR.TITLE",
        path: "/calendar",
        component: CalendarHome,
        authRequired: true,
        viewInNavigationPanel: true,
        iconName: "calendar-alt",
        name: "calendar",
        access: "calendar_view",
        children: [
            {
                systemName: "CALENDAR.TITLE",
                path: "/calendar",
                component: Calendar,
                name: "calendar-calendar",
                exact: true
            },
            {
                systemName: "CALENDAR.EVENTS.TITLE",
                path: "/calendar/events",
                component: Events,
                name: "calendar-events",
                exact: true
            }
        ]
    },
    {
        systemName: "WIKI.TITLE",
        path: "/wiki",
        component: WikiMain,
        authRequired: true,
        exact: false,
        name: "wiki",
        viewInNavigationPanel: true,
        iconName: "life-ring",
        skipForFlatten: true,
        access: "wiki_view",
        children: [
            {
                systemName: "WIKI.TITLE",
                path: "/wiki",
                component: WikiDefaultPage,
                name: "default_page",
                authRequired: true,
                exact: true,
            },
            {
                systemName: "WIKI.CREATE-PAGE.TITLE",
                path: "/wiki/new-page",
                component: WikiEditPage,
                name: "create_page",
                authRequired: true,
                exact: true,
                access: "wiki_edit",
            },
            {
                systemName: "WIKI.EDIT-PAGE.TITLE",
                path: "/wiki/page/:id/edit",
                component: WikiEditPage,
                name: "edit_page",
                authRequired: true,
                exact: true,
                access: "wiki_edit",
            },
            {
                systemName: "WIKI.VIEW.TITLE",
                path: "/wiki/page/:id",
                component: WikiViewPage,
                name: "view_page",
                authRequired: true,
                exact: true,
            }
        ]
    },
    {
        systemName: "SETTINGS_PAGE.TITLE",
        path: "/settings",
        component: Settings,
        authRequired: true,
        viewInNavigationPanel: true,
        iconName: "cog",
        exact: false,
        name: "settings",
        children: [
            {
                systemName: "SETTINGS.TITLE",
                path: "/settings/common",
                component: SettingsCommon,
                access: "branch_list",
                authRequired: true,
                name: "common-settings",
                exact: false,
                children: [
                    {
                        systemName: "LANGUAGES.TITLE",
                        path: "/settings/common/languages",
                        component: LanguagesList,
                        access: "i18n.management",
                        name: "languages",
                        exact: true
                    },
                    {
                        systemName: "ANNOUNCEMENTS.TITLE",
                        path: "/settings/common/announcements",
                        access: "announcement_manage",
                        component: Announcements,
                        name: "announcements",
                        exact: true
                    },
                    {
                        systemName: "MARKETING_AGREEMENT.TITLE",
                        path: "/settings/common/marketing-agreements",
                        component: Agreements,
                        name: "marketing-agreements",
                        exact: true
                    },
                    {
                        systemName: "PURCHASING-ALLIANCES.TITLE",
                        path: "/settings/common/purchasing-alliances",
                        component: PurchasingAlliancesHome,
                        name: "common-settings-purchasing-alliances-home",
                        exact: false,
                        children: [
                            {
                                systemName: "PURCHASING-ALLIANCES.TIER_1.TITLE",
                                path: "/settings/common/purchasing-alliances/tier1",
                                component: PurchasingAlliances,
                                name: "common-settings-purchasing-alliances-tier1",
                                exact: true,
                                configData: {type: 'tier1'}
                            },
                            {
                                systemName: "PURCHASING-ALLIANCES.TIER_2.TITLE",
                                path: "/settings/common/purchasing-alliances/tier2",
                                component: PurchasingAlliances,
                                name: "common-settings-purchasing-alliances-tier2",
                                exact: true,
                                configData: {type: 'tier2'}
                            }
                        ]
                    }
                ]
            },
            {
                systemName: "BRANCHES.TITLE",
                path: "/settings/branches",
                component: Branches,
                access: "branch_list",
                authRequired: true,
                name: "branches",
            },
            {
                systemName: "LOGGER.TITLE",
                path: "/settings/logger",
                component: SmartLogger,
                authRequired: true,
                name: "logger",
            },
            {
                systemName: "ACTIONS",
                path: "/settings/actions",
                component: SettingsActions,
                access: "branch_list",
                authRequired: true,
                name: "branches",
            },
            {
                systemName: "SETTINGS.WEBSITE.TITLE",
                path: "/settings/web-site",
                component: WebSiteSettings,
                authRequired: true,
                exact: false,
                name: "web-site",
                fullWidth: true,
                children: [
                    {
                        systemName: "SETTINGS.WEBSITE.PAGE.VIEW.TITLE",
                        path: "/settings/web-site/page/:pageId",
                        component: EditPageWebSiteSettings,
                        exact: true,
                        name: "web-site_page_view",
                        fullWidth: true
                    },
                    {
                        systemName: "SETTINGS.WEBSITE.PAGE.LIST.TITLE",
                        path: "/settings/web-site/categories/:id/pages",
                        component: ListPagesWebSiteSettings,
                        exact: true,
                        name: "web-site_category_edit",
                        fullWidth: false
                    },
                    {
                        systemName: "SETTINGS.WEBSITE.CATEGORY.EDIT.TITLE",
                        path: "/settings/web-site/categories/new-category",
                        component: EditCategoriesWebSiteSettings,
                        exact: true,
                        name: "web-site_category_edit",
                        fullWidth: false
                    },
                    {
                        systemName: "SETTINGS.WEBSITE.CATEGORY.EDIT.TITLE",
                        path: "/settings/web-site/categories/:id",
                        component: EditCategoriesWebSiteSettings,
                        exact: true,
                        name: "web-site_category_edit",
                        fullWidth: false
                    },
                    {
                        systemName: "SETTINGS.WEBSITE.PAGE.EDIT.TITLE",
                        path: "/settings/web-site/categories/:id/new-page",
                        component: EditPageWebSiteSettings,
                        exact: true,
                        name: "web-site_page_edit",
                        fullWidth: true
                    },
                ]
            },
            {
                systemName: "BRANCH.DATA.TITLE",
                path: "/settings/branches/:branchId",
                component: BranchData,
                access: "branch_list",
                authRequired: true,
                exact: false,
                name: "branch",
                skipForFlatten: true,
                hidden: true,
                children: [
                    {
                        systemName: "BRANCH.DATA.TITLE",
                        path: "/settings/branches/:branchId",
                        component: BranchDataMain,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_banks",
                    },
                    {
                        systemName: "BRANCH.DATA.BANKS.TITLE",
                        path: "/settings/branches/:branchId/banks",
                        component: BranchDataBanks,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_banks",
                    },
                    {
                        systemName: "WAREHOUSES.TAXES.TITLE",
                        path: "/settings/branches/:branchId/tax-rules",
                        component: BranchDataTaxRules,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_tax-rules",
                    },
                    {
                        systemName: "WAREHOUSES.TITLE",
                        path: "/settings/branches/:branchId/warehouses",
                        component: BranchDataWarehouses,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_warehouses"
                    },
                    {
                        systemName: "WAREHOUSES.PRIORITY-GROUPS.TITLE",
                        path: "/settings/branches/:branchId/warehouses/priority",
                        component: BranchDataWarehousesPriorityGroups,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_warehouses_priority_groups",
                        hidden: true
                    },
                    {
                        systemName: "WAREHOUSE.TITLE",
                        path: "/settings/branches/:branchId/warehouses/:warehouseId",
                        component: BranchDataWarehouse,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_warehouse",
                        hidden: true
                    },
                    {
                        systemName: "BRANCH.DATA.DEPARTMENTS.TITLE",
                        path: "/settings/branches/:branchId/departments",
                        component: BranchDataDepartments,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_departments",
                    },
                    {
                        systemName: "BRANCH.DATA.DEPARTMENTS.SINGLE_TITLE",
                        path: "/settings/branches/:branchId/departments/:departmentId",
                        component: BranchDataDepartment,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_department",
                        hidden: true
                    },
                    {
                        systemName: "BRANCH.DATA.SHIPPING_COURIERS.TITLE",
                        path: "/settings/branches/:branchId/shipping-couriers",
                        component: BranchDataCouriers,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_couriers",
                    },
                    {
                        systemName: "BRANCH.DATA.SHIPPING_COURIER.TITLE",
                        path: "/settings/branches/:branchId/shipping-couriers/:courierId",
                        component: BranchDataCourier,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_courier",
                        hidden: true
                    },
                    {
                        systemName: "BRANCH.DATA.PRICES.TITLE",
                        path: "/settings/branches/:branchId/prices",
                        component: BranchDataPrices,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_prices",
                    },
                    {
                        systemName: "BRANCH.DATA.EMPLOYEES.TITLE",
                        path: "/settings/branches/:branchId/employees",
                        component: BranchDataEmployees,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_employees",
                    },
                    {
                        systemName: "BRANCH.DATA.COUNTRIES.TITLE",
                        path: "/settings/branches/:branchId/countries",
                        component: BranchDataCountries,
                        access: "branch_list",
                        exact: true,
                        authRequired: true,
                        name: "branch_countries",
                    }
                ],
            }
        ]
    },
    // {
    //     systemName: "LOGOUT.TITLE",
    //     path: "/logout",
    //     component: Logout,
    //     name: "logout",
    // },
    {
        systemName: "QUEUE.TITLE",
        path: "/queue",
        component: QueueStatus,
        access: "faker",
        authRequired: true,
        name: "queue",
    },
    {
        systemName: "MAIN_PAGE",
        path: "/",
        component: Index,
        authRequired: true,
        name: "main",
        exact: false
    },
    {
        systemName: "NOT_FOUND.TITLE",
        path: "*",
        component: NotFound,
        exact: true,
        name: "not_found",
    },
];

export const reactRouterConfig = createRouteConfig(routers)