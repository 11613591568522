import React from "react";
import {WidgetProps} from "../widget";
import {UIInputWidgetCustomSelect} from "../../../../../ui/input/widget/custom-select/ui.input.widget.custom-select";
export const CustomMultipleSelectWidget: React.FC<WidgetProps> = ({onChange, value, multiple, options}) => {

    return (
        <UIInputWidgetCustomSelect
            onChange={onChange as any}
            value={value as any}
            multiple={multiple}
            defaultOptions={options}
        />
    )
}