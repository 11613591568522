import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Shipment} from "../../../../models/parcels/shipment";
import {Col, Row, Typography} from "antd";
import {ShipmentPalletCard} from "../card/shipment-pallet-card";
import {ShipmentBoxCard} from "../card/shipment-box.card";
import "./shipment.parcels.scss"

interface InvoiceDataHeaderProps {
    shipment: Shipment;
    reload: () => void;
}

export const ShipmentParcels: React.FC<InvoiceDataHeaderProps> = ({shipment, reload}) => {
    const t = useTranslate();
    //
    // const [boxes, setBoxes] = useState<Parcel[]>([]);
    // const [pallets, setPallets]  = useState<Parcel[]>([]);
    //
    // useEffect(() =>  {
    //     const boxes: Parcel[] = [];
    //     const pallets: Parcel[] = [];
    //     shipment.parcels.map(parcel => {
    //         if (parcel.type === "box") {
    //             boxes.push(parcel);
    //         } else {
    //             pallets.push(parcel);
    //         }
    //     })
    //     setBoxes(boxes);
    //     setPallets(pallets);
    // }, [shipment.parcels])
    //
    // return (
    //     <Row gutter={[25, 25]}>
    //         {pallets ? (
    //             <Col span={24}>
    //                 <Typography.Title level={3}>Pallets</Typography.Title>
    //                 <Row gutter={[15, 15]}>
    //                     {pallets.map(parcel => <Col span={24}><ShipmentPalletCard parcel={parcel} /></Col>)}
    //                 </Row>
    //             </Col>
    //         ) : null}
    //         {boxes ? (
    //             <Col span={24}>
    //                 <Typography.Title level={3}>Boxes</Typography.Title>
    //                 <Row gutter={[15, 15]}>
    //                     {boxes.map(parcel => <Col span={24}><ShipmentBoxCard parcel={parcel} /></Col>)}
    //                 </Row>
    //             </Col>
    //         ) : null}
    //     </Row>
    // );

    return (
        <Row gutter={[25, 25]}>
            <Col span={24} className={"parcels"}>
                <Typography.Title level={3}>Parcels</Typography.Title>
                <Row gutter={[15, 15]}>
                    {shipment.parcels.map(parcel => (
                        parcel.type === "box"
                            ? <Col span={24}><ShipmentBoxCard parcel={parcel} reload={reload} /></Col>
                            : <Col span={24}><ShipmentPalletCard parcel={parcel} reload={reload} /></Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
}