import { Currency } from "./price.helper";

export type CurrencyCode = Lowercase<Currency>;

export interface Amount {
    value: number;
    currencyCode: CurrencyCode;
}

export enum DisplayType {
    DOT = 'dot',
    COMMA = 'comma'
}