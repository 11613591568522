import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import React, {useState} from "react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {Alert, Button, DatePicker, Form, Input, Radio, Select, Space, Typography} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {AgentDataInvoicesNewOrderTable} from "./order-table/agent.data.invoices.new.order-table";
import {AgentDataInvoicesNewInvoicesTable} from "./invoices-table/agent.data.invoices.new.invoices-table";
import {invoiceService} from "../../../../../services/documents/invoices/invoices.service";
import {AxiosError} from "axios";
import {CustomerAccountAddress} from "../../../../../models/accounts/customer-account/address/customer.account.address";
import {SelectAddressModal} from "./select-address-modal/select-address-modal";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {WrongDeliveryModal} from "./wrong-delivery-modal/wrong-delivery-modal";
import {DispatchType} from "../../../../../models/documents/invoices/invoice";

interface ErrorData {
    reason: 'COMBINE_DELIVERY_AND_NOT_DELIVERY' | 'NOT_ALL_DELIVERY_INCLUDED' | 'DIFFERENT_ADDRESSES';
    message: string;
    addresses: CustomerAccountAddress[];
    addressType: string;
}

interface AgentDataMergedInvoicesProps extends ComponentPropsFromRoute {
}

export const AgentDataInvoicesNew: React.FC<AgentDataMergedInvoicesProps> = () => {

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const { account } = useRequiredStore(AgentDataStoreContext);

    const [ form ] = useForm();

    const [addresses, setAddresses] = useState<CustomerAccountAddress[]>([]);
    const [addressUseAs, setAddressUseAs] = useState<string>("");
    const [addressModalShown, setAddressModalShown] = useState<boolean>(false);

    const [wrongDeliveryModalShown, setWrongDeliveryModalShown] = useState<boolean>(false);
    const [wrongDeliveryReason, setWrongDeliveryReason] = useState<string>("");
    const [wrongDeliveryTextReason, setWrongDeliveryTextReason] = useState<string>("");

    const [usedThirdPartyWarehouses, setUsedThirdPartyWarehouses] = useState<boolean>(false);

    const createAndUpload = () => {
        form.setFieldsValue({upload: true});
        form.submit();
    }

    const create = () => {
        form.setFieldsValue({upload: false});
        form.submit();
    }

    return (
        <>
            <Typography.Title level={3} style={{marginTop: 15}}>
                {t("New sale document")}
            </Typography.Title>

            <Alert
                style={{marginBottom: 20}}
                message={<>
                    {t("PAYMENT_TERMS")}: <b>{account?.paymentTerms || '-'}</b>
                    <br />
                    {t("SHIPMENT_CONDITION")}: <b>{account?.shipmentCondition || '-'}</b>
                </>}
                type="warning"
            />

            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={{type: "new", date: dayjs(), force: false, skipDeliveryCheck: false}}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        values.date = values.date.format();
                        if (values.address) {
                            values.address.country = values.address.country.id;
                            delete values.address.id;
                            delete values.address.firstName;
                            delete values.address.lastName;

                        }
                        invoiceService.createInvoice(values)
                            .then(
                                data => navigate("/invoices/" + data.id),
                                (e: AxiosError<ErrorData>) => {

                                    switch (e.response?.data.reason) {
                                        case 'COMBINE_DELIVERY_AND_NOT_DELIVERY':
                                        case 'NOT_ALL_DELIVERY_INCLUDED':
                                            setWrongDeliveryModalShown(true);
                                            setWrongDeliveryReason(e.response?.data.reason)
                                            setWrongDeliveryTextReason(e.response?.data.message)
                                            break;
                                        case 'DIFFERENT_ADDRESSES':
                                            setAddresses(e.response?.data.addresses.map((a, i) => {
                                                a.id = i;
                                                return a;
                                            }))
                                            setAddressUseAs(e.response.data.addressType)
                                            setAddressModalShown(true)
                                            break;
                                    }
                                }
                            )
                    })
                }>

                <Form.Item
                    label="Action type"
                    name="type"
                    rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                >
                    <Radio.Group disabled={loading}>
                        <Radio.Button value="new">Create a new sale document</Radio.Button>
                        <Radio.Button value="exists">Add to exists sale document</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    shouldUpdate={(p, n) => p.type !== n.type}
                    noStyle
                >
                    {() => (
                        <>
                            {form.getFieldValue('type') === 'exists' ? (
                                <Form.Item
                                    name="invoice"
                                    label={t("Sale documents")}
                                    rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                                >
                                    <AgentDataInvoicesNewInvoicesTable accountId={account!.id}/>
                                </Form.Item>
                            ) : null}
                        </>
                    )}
                </Form.Item>

                <Form.Item
                    name="date"
                    label={t("DATE")}
                    rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                >
                    <DatePicker
                        disabled={loading}
                        style={{ width: "100%" }}
                        placeholder={t("DATE")}
                        format="DD-MM-YYYY"
                    />
                </Form.Item>

                <Form.Item
                    name="orders"
                    label={t("ORDERS.TITLE")}
                    rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                >
                    <AgentDataInvoicesNewOrderTable
                        accountId={account!.id}
                        changeUsedThirdPartyWarehouses={(value, defaultDispatchType) => {
                            setUsedThirdPartyWarehouses(value);
                            form.setFieldsValue({dispatchType: value ? defaultDispatchType : undefined});
                        }}
                    />
                </Form.Item>

                {usedThirdPartyWarehouses ? (
                    <Form.Item
                        name="dispatchType"
                        label={t("DOCUMENT.INVOICE.DISPATCH_TYPE.TITLE")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select>
                            {Object.values(DispatchType).map(value => (
                                <Select.Option key={value} value={value}>
                                    {t('DOCUMENT.INVOICE.DISPATCH_TYPE.' + value.toUpperCase())}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}

                <Form.Item shouldUpdate>
                    {() => (
                        <>
                            <Form.Item name="upload">
                                <Input type={"hidden"} />
                            </Form.Item>
                            <Form.Item name="address">
                                <Input type={"hidden"} />
                            </Form.Item>
                            <Form.Item name="applyAddressAs">
                                <Input type={"hidden"} />
                            </Form.Item>
                            <Form.Item name="skipDeliveryCheck">
                                <Input type={"hidden"} />
                            </Form.Item>
                            <Form.Item name="addForgotDeliveryOrders">
                                <Input type={"hidden"} />
                            </Form.Item>
                            {form.getFieldValue('type') === 'new' ? (
                                <Space direction={"horizontal"}>
                                    <Button
                                        disabled={loading}
                                        type={"primary"}
                                        onClick={createAndUpload}
                                    >Create & Upload to 1C</Button>
                                    <Button
                                        disabled={loading}
                                        onClick={create}
                                    >Just create</Button>
                                </Space>
                            ) : (
                                <Space direction={"horizontal"}>
                                    <Button
                                        disabled={loading}
                                        type={"primary"}
                                        onClick={create}
                                    >Add to sale document</Button>
                                </Space>
                            )}
                        </>
                    )}
                </Form.Item>

            </Form>

            {addressModalShown ? (
                <SelectAddressModal
                    addresses={addresses}
                    useAs={addressUseAs}
                    onClose={() => setAddressModalShown(false)}
                    onComplete={(address) => {
                        form.setFieldsValue({address: addresses[address], applyAddressAs: addressUseAs});
                        form.submit();
                    }}
                />
            ) : null}

            {wrongDeliveryModalShown ? (
                <WrongDeliveryModal
                    reason={wrongDeliveryReason}
                    textReason={wrongDeliveryTextReason}
                    onClose={() => setWrongDeliveryModalShown(false)}
                    onComplete={(skipDeliveryCheck, addForgotDeliveryOrders) => {
                        form.setFieldsValue({skipDeliveryCheck, addForgotDeliveryOrders});
                        form.submit();
                    }}
                />
            ) : null}
        </>
    )
}