import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {
    Courier, EditableParcel, EditShipment,
    Package,
    Shipment,
    ShipmentItem,
    ShipmentRequest
} from "../../models/parcels/shipment";
import {stringify} from "query-string";

export class ShipmentsService {
    private api: AxiosInstance = API;

    public getCouriers(): Promise<Courier[]> {
        return this.api.get("orders/shipments/couriers").then(x => x.data);
    }

    public getPackages(): Promise<Package[]> {
        return this.api.get("orders/shipments/packages").then(x => x.data);
    }

    public getShipments(request: PaginatedRequest): Promise<PaginatedResult<Shipment>> {
        return this.api.get<PaginatedResult<Shipment>>("orders/shipments", {
            params: request.toParams()
        }).then(x => x.data);
    }

    public getShipment(id: number | string): Promise<ShipmentItem> {
        return this.api.get<ShipmentItem>("orders/shipments/" + id).then(x => x.data);
    }

    public edit(id: number | string, request: EditShipment): Promise<any> {
        return this.api.post("orders/shipments/" + id, request);
    }

    public updateShipment(id: number | string, request: ShipmentRequest): Promise<Shipment> {
        return this.api.post<Shipment>("orders/shipments/" + id, request).then(x => x.data);
    }

    public updateConsignor(id: number | string, request: any): Promise<any> {
        return this.api.post("orders/shipments/" + id + "/consignor", request);
    }

    public updateConsignee(id: number | string, request: any): Promise<any> {
        return this.api.post("orders/shipments/" + id + "/consignee", request);
    }

    public dispatchShipment(id: number | string): Promise<void> {
        return this.api.post("orders/shipments/" + id + "/dispatch");
    }

    public dispatchShipments(shipments: number[], date: string): Promise<void> {
        return this.api.post("orders/shipments/dispatch", {shipments: shipments, date: date});
    }

    public createStickers(shipments: number[]): Promise<void> {
        return this.api.post("orders/shipments/labels", {id: shipments});
    }

    public createAutoDocSticker(shipment: number): Promise<void> {
        return this.api.get("orders/shipments/" + shipment + "/autodoc-psl");
    }

    public createDHLManifest(shipments: number[]): Promise<{blob: Blob, filename: string}> {
        return this.api
            .get("orders/shipments/manifest/dhl", {
                responseType: 'blob',
                params: {shipments: shipments},
                paramsSerializer: (p: Record<any, any>) => stringify(p, {arrayFormat: "bracket"})
            })
            .then(resp => {
                return {
                    blob: resp.data,
                    filename: resp.headers["content-disposition"].split("filename=")[1].replaceAll('"', '')
                };
            });
    }

    public createTNTManifest(shipments: number[]): Promise<{blob: Blob, filename: string}> {
        return this.api
            .get("orders/shipments/manifest/tnt", {
                responseType: 'blob',
                params: {shipments: shipments},
                paramsSerializer: (p: Record<any, any>) => stringify(p, {arrayFormat: "bracket"})
            })
            .then(resp => {
                return {
                    blob: resp.data,
                    filename: resp.headers["content-disposition"].split("filename=")[1].replaceAll('"', '')
                };
            });
    }

    public createVenipakManifest(): Promise<{blob: Blob, filename: string}> {
        return this.api
            .get("orders/shipments/manifest/venipak", {
                responseType: 'blob'
            })
            .then(resp => {
                return {
                    blob: resp.data,
                    filename: resp.headers["content-disposition"].split("filename=")[1].replaceAll('"', '')
                };
            });
    }

    public createList(shipments: number[]): Promise<{blob: Blob, filename: string}> {
        return this.api
            .get("orders/shipments/list", {
                responseType: 'blob',
                params: {shipments: shipments},
                paramsSerializer: (p: Record<any, any>) => stringify(p, {arrayFormat: "bracket"})
            })
            .then(resp => {
                return {
                    blob: resp.data,
                    filename: resp.headers["content-disposition"].split("filename=")[1].replaceAll('"', '')
                };
            });
    }

    public handoverSpeedy(shipments: number[]): Promise<{}> {
        return this.api
            .get("orders/shipments/handover/speedy", {
                params: {shipments: shipments},
                paramsSerializer: (p: Record<any, any>) => stringify(p, {arrayFormat: "bracket"})
            });
    }

    public getShipmentByParcelId(id: string): Promise<ShipmentItem> {
        return this.api.get<ShipmentItem>("orders/shipments/parcel/" + id + "/shipment").then(x => x.data);
    }

    public updateParcelDetails(id: number, data: any): Promise<void> {
        return this.api.post("orders/shipments/parcel/" + id, data).then(x => x.data);
    }

    public customReShip(shipmentId: number, courier: string, parcelInfo: EditableParcel[]): Promise<Shipment> {

        const formData = new FormData();

        const body: any[] = [];

        parcelInfo.forEach((info, key) => {
            formData.append('parcelInfo[' + key + '][label]', info.label!);
            const cloned = {...info};
            delete cloned.label;
            body.push(cloned);
        })

        formData.append('_', JSON.stringify({parcelInfo: body, courier}));

        return this.api.post("shipments/" + shipmentId + "/custom-reship", formData);
    }

    public reShip(shipmentId: number, courier: string, data: Record<string, any>): Promise<Shipment> {
        return this.api.post("shipments/" + shipmentId + "/reship", {courier, ...data});
    }
}

export const shipmentsService = new ShipmentsService();