import { NewOrder } from "./../../../models/orders/orders-new/orders.new";
import { AxiosInstance } from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import {
    NewOrders,
    Order,
    OrderListClientAgreement,
    OrdersSummary,
    RetailOrder
} from "../../../models/orders/orders-list/order";
import { Document } from "../../../models/documents/document";

export class OrdersListService {
    api: AxiosInstance = API;

    getAllWholesaleOrders(request: PaginatedRequest): Promise<PaginatedResult<Order>> {
        return this.api
            .get<PaginatedResult<Order>>("orders/wholesale", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllRetailOrders(request: PaginatedRequest): Promise<PaginatedResult<RetailOrder>> {
        return this.api
            .get<PaginatedResult<Order>>("orders/retail", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllRetailOrderChildren(parentOrderId: number): Promise<RetailOrder[]> {
        return this.api
            .get<RetailOrder[]>("orders/retail/" + parentOrderId + "/children")
            .then((x) => x.data);
    }

    getAgentOrdersByAccount(request: PaginatedRequest, accountId: string): Promise<PaginatedResult<Order>> {
        request.addFilter("account", accountId);
        return this.api
            .get<PaginatedResult<Order>>("orders", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    createProformaByOrders(values: { date: string; orders: number[] }): Promise<Document> {
        return this.api.post<Document>("documents/proforma/orders", values).then((x) => x.data);
    }

    createProformaByAccount(values: { date: string; account: number }): Promise<Document> {
        return this.api.post<Document>("documents/proforma/account", values).then((x) => x.data);
    }

    getClientAgreements(id: number): Promise<OrderListClientAgreement[]> {
        return this.api.get<OrderListClientAgreement[]>("accounts/" + id + "/agreements").then((x) => x.data);
    }

    getNewOrders(): Promise<NewOrder[]> {
        return this.api.get<NewOrder[]>("orders/new").then((x) => x.data);
    }

    getSummary(ids: number[]): Promise<OrdersSummary> {
        return this.api.get<OrdersSummary>("orders/summary", { params: { orders: ids } }).then((x) => x.data);
    }

    cancelOrders(ids: number[]) {
        return this.api.delete("orders", { params: { ids } });
    }

    createICExportCSV(ids: number[]): Promise<BlobAndFilename> {
        return this.api.get("orders/ic-export", {responseType: "blob", params: {orders: ids}}).then(getBlobAndFileName);
    }

    getAllNewOrders(accountId: number): Promise<NewOrders> {
        return this.api
            .get<NewOrders>("accounts/" + accountId + "/orders/new")
            .then((x) => x.data);
    }
}

export const ordersListService = new OrdersListService();
