import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Form, Modal} from "antd";
import {Property} from "../../../../../models/property/property";
import {propertyService} from "../../../../../services/property/property.service";
import {UIInputWidgetPropertyLocation} from "../../../ui/input/widget/property-location/ui.input.widget.property-location";
import {useNavigate} from "react-router";

interface ChangeLocationPropertyModalProps {
    onClose: () => void;
    property: Property;
}

export const ChangeLocationPropertyModal: React.FC<ChangeLocationPropertyModalProps> = ({ onClose, property }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("PROPERTY.PROPERTY_LIST.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={{
                    location: property.location.id,
                }}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        values.purchaseAmountValue = parseFloat(values.purchaseAmountValue);
                        setLoading(true);
                        try {
                            if (property) {
                                await propertyService.editPropertyLocation(property.id, values);
                                onClose();
                            } else {
                                const newProperty = await propertyService.createProperty(values);
                                onClose();
                                navigate('/property/' + newProperty.id);
                            }
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields || []);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PROPERTY.PROPERTY.LOCATION")}
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPropertyLocation
                        selectFirstOnLoad={true}
                    />
                </Form.Item>
            </Form>

        </Modal>
    );
}