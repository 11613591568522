import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import {ShippingRule} from "../../../../../models/shipping/shipping";
import {useRequiredStore} from "../../../../../utils/store";
import {observer} from "mobx-react";
import {BranchDataStoreContext} from "../../branch.data.store";
import {currencies, Currency} from "../../../../../models/prices/price.helper";
import {UiInputWidgetCountry} from "../../../ui/input/widget/country/ui.input.widget.country";
import {UiInputWidgetCity} from "../../../ui/input/widget/city/ui.input.widget.city";
import {UiInputWidgetCityArea} from "../../../ui/input/widget/city-area/ui.input.widget.city-area";
import {useParams} from "react-router-dom";
import {Country} from "../../../../../models/countries/country";
import {branchesService} from "../../../../../services/branches/branches.service";

export interface BranchDataRulesEditModalProps {
    rule: ShippingRule | null;
    onSubmit(sourceRule: ShippingRule | null, rule: ShippingRule): Promise<ShippingRule>;
    onClose(): void;
}

export const BranchDataRulesEditModal: React.FC<BranchDataRulesEditModalProps> = observer(({rule, onSubmit, onClose}) => {
    const t = useTranslate();
    const {branchId} = useParams<{ branchId: string }>();

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const branchStore = useRequiredStore(BranchDataStoreContext);

    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        setLoading(true);
        try {
            branchesService.getCountries(branchId!).then(values => setCountries(values));
        } finally {
            setLoading(false);
        }
    }, [branchId])

    const selectAllCountries = () => {
        form.setFieldsValue({countries: countries.map(country => ({
                country: country.id,
                city: null,
                area: null
        }))});
    }

    let defaultValues: ShippingRule | {} = {};
    if (rule) {
        defaultValues = {
            ...rule,
            cost: rule.cost.value,
            countries: rule.countries.map(value => ({
                country: value.country?.id!,
                city: value.city?.id,
                area: value.area?.id
            }))
        }
    } else {
        defaultValues = {
            priceFrom: 0,
            riceUntil: 0,
            weightFrom: 0,
            weightUntil: 0,
            cost: 0,
            howCost: true,
            countries: []
        }
    }

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={
                rule ? t("BRANCH.DATA.SHIPPING.RULE.EDIT") : t("BRANCH.DATA.SHIPPING.RULE.ADD")
            }
            okText={rule ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            width={"600px"}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        values = {
                            priceFrom: parseFloat(values.priceFrom),
                            priceUntil: parseFloat(values.priceUntil),
                            weightFrom: parseFloat(values.weightFrom),
                            weightUntil: parseFloat(values.weightUntil),
                            countries: values.countries || [],
                            cost: parseFloat(values.cost),
                            costDescription: values.costDescription,
                            showCost: values.showCost,
                            onSelectPopUpText: values.onSelectPopUpText
                        }

                        onSubmit(rule, values)
                            .catch(() => (e: any) => {
                                form.setFields(e.response?.data?.fields);
                                setLoading(false);
                            })
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="weightFrom"
                            label={t("SHIPPING.RULE.WEIGHT_FROM")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter="kg."
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.WEIGHT_FROM")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="weightUntil"
                            label={t("SHIPPING.RULE.WEIGHT_UNTIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter="kg."
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.WEIGHT_UNTIL")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="priceFrom"
                            label={t("SHIPPING.RULE.PRICE_FROM")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.PRICE_FROM")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="priceUntil"
                            label={t("SHIPPING.RULE.PRICE_UNTIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.PRICE_UNTIL")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space direction={"vertical"} size={[10, 10]} style={{marginBottom: 15}}>
                            <h4>{t("COUNTRIES")}</h4>
                            <Form.List name="countries">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row gutter={[10, 10]}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'country']}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <UiInputWidgetCountry
                                                            branchId={branchId!}
                                                            onChange={() => {
                                                                form.setFieldValue(["countries", key, "city"], undefined)
                                                                form.setFieldValue(["countries", key, "area"], undefined)
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) => prevValues.countries[key]?.country !== curValues.countries[key]?.country}
                                                    >
                                                        {() => (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'city']}
                                                                shouldUpdate={true}
                                                            >
                                                                <UiInputWidgetCity
                                                                    countryCode={form.getFieldValue(["countries", key, "country"])}
                                                                    onChange={() => {
                                                                        form.setFieldValue(["countries", key, "area"], undefined)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) => prevValues.countries[key]?.city !== curValues.countries[key]?.city}
                                                    >
                                                        {() => (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'area']}
                                                                shouldUpdate={true}
                                                            >
                                                                <UiInputWidgetCityArea
                                                                    cityId={form.getFieldValue(["countries", key, "city"])}
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Button onClick={() => remove(name)} block>X</Button>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Space direction={"vertical"} size={[10, 10]}>
                                                <Button type="dashed" onClick={() => add()} block>Add country</Button>
                                                <Button type="dashed" onClick={() => selectAllCountries()} block>Add all countries</Button>
                                            </Space>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="cost"
                            label={t("COST")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("COST")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="showCost"
                            label={t("SHIPPING.RULE.SHOW_COST")}
                            valuePropName={"checked"}
                        >
                            <Checkbox
                                disabled={loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="costDescription"
                            label={t("DESCRIPTION")}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("SHIPPING.RULE.SHOW_COST")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="onSelectPopUpText"
                            label={t("SHIPPING.RULE.ON_SELECT_POPUP_TEXT")}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("SHIPPING.RULE.ON_SELECT_POPUP_TEXT")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
