import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Property, PropertyFile, PropertyFileType} from "../../../../../models/property/property";
import {Tabs, TabsProps} from "antd";
import {ReviewListPropertyImages} from "./images/images.property.view";
import {isAllowed} from "../../../../../utils/helpers";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";

interface ReviewListPropertyViewProps {
    property: Property;
}

const getGroupedFiles = (property: Property): Record<PropertyFileType, PropertyFile[]> => {
    const map: Record<string, PropertyFile[]> = {};
    Object.values(PropertyFileType).forEach((type) => map[type] = [])

    property.files.forEach(file => {
        map[file.type].push(file);
    });

    return map
}

export const FilesListPropertyView: React.FC<ReviewListPropertyViewProps> = ({property}) => {

    const t = useTranslate();
    const groupedFiles = useMemo(() => getGroupedFiles(property), [property])

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const items: TabsProps['items'] = [
        {
            key: "1",
            label: "Photo",
            children: <ReviewListPropertyImages
                property={property}
                files={groupedFiles.photo}
                type={PropertyFileType.PHOTO}
            />
        },
        {
            key: "2",
            label: "Reviews",
            children: <ReviewListPropertyImages
                property={property}
                files={groupedFiles.inventory_photo_confirmation}
                disableUpload={true}
                type={PropertyFileType.INVENTORY_PHOTO_CONFIRMATION}
            />
        }
    ];

    if (isAllowed(attributes, "property_management")) {
        items.push(
            {
                key: "3",
                label: "Documents",
                children: <ReviewListPropertyImages
                    property={property}
                    files={groupedFiles.document}
                    type={PropertyFileType.DOCUMENT}
                />
            }
        );
    }

    return (
        <Tabs
            defaultActiveKey="1"
            items={items}
        />
    )
}