import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Parcel} from "../../../../models/parcels/shipment";
import {Button, Card, Col, Row, Space, Statistic} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ShipmentBox} from "./shipment-box";
import {Value} from "../../../common/value/value";
import {ParcelEditModal} from "../modal/parcel-edit/parcel-edit.modal";

interface InvoiceDataHeaderProps {
    parcel: Parcel;
    reload: () => void;
}

export const ShipmentPalletCard: React.FC<InvoiceDataHeaderProps> = ({parcel, reload}) => {
    const t = useTranslate();

    const [editModalShown, setEditModalShown] = useState<boolean>(false);

    return (
        <Card
            bordered={false}
            headStyle={{color: '#fff', backgroundColor: '#4a4a4a'}}
            bodyStyle={{padding: "10px 10px", backgroundColor: '#f7f7f7'}}
            title={<><FontAwesomeIcon icon={"pallet"} color={"#676767"} /><span style={{marginLeft: 10}}>Pallet # {parcel.number}</span></>}
            extra={<Space>
                <Button
                    type={"text"}
                    style={{color: '#ffffff'}}
                    icon={<FontAwesomeIcon icon={["fas", "gear"]} />}
                    onClick={() => setEditModalShown(true)}
                />
            </Space>}
        >
            <Row gutter={[40, 20]}>
                <Col span={18}>
                    <Row gutter={[10, 10]}>
                        {parcel.children.map(parcel => (
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Card
                                    title={<><FontAwesomeIcon icon={"box"} color={"#676767"} /><span style={{marginLeft: 10}}># {parcel.number}</span></>}
                                    headStyle={{paddingLeft: 15}}
                                    bodyStyle={{padding: 0}}
                                >
                                    <ShipmentBox parcel={parcel} showInfo={false} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={6}>
                    <Space direction={"vertical"} size={[10, 10]}>
                        <Statistic
                            title="Track Number"
                            valueRender={() => <Value value={parcel.trackCode} />}
                        />
                        <Statistic
                            title="Box Number"
                            valueRender={() => <Value value={parcel.boxCode} />}
                        />
                        <Statistic title="Net weight"
                                   value={parcel.netWeight}
                                   precision={3}
                                   suffix={"kg."}
                        />
                        <Statistic title="Gross weight"
                                   value={parcel.grossWeight}
                                   precision={3}
                                   suffix={"kg."}
                        />
                    </Space>
                </Col>
            </Row>
            {editModalShown ? (
                <ParcelEditModal
                    parcel={parcel}
                    onClose={() => setEditModalShown(false)}
                    onSuccess={reload}
                />
            ) : null}
        </Card>
    );
}