import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, Form, Input, Row, Space, Typography} from "antd";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {UIInputWidgetAccountAddress} from "../../../ui/input/account-address/ui.input.widget.account-address";

export const AgentDataTecOrder: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateTecOrder(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        accounts: account.tecOrderAccounts.map(a => ({
            tecOrderId: a.tecOrderId,
            tecOrderSellerId: a.tecOrderSellerId,
            useGolda: a.useGolda,
            defaultShippingAddress: a.defaultShippingAddress.id
        })),
    }

    return <Form
        size={"middle"}
        form={form}
        layout="vertical"
        name={"client_form"}
        initialValues={defaultValues}
        onFinish={async (values) => {
            await form.validateFields();
            await submit(values)
        }}
    >
        <Typography.Title level={3}>{t("CLIENT.TEC-ORDER.TITLE")}</Typography.Title>
        <Row gutter={[30, 30]} align={"top"}>
            <Col span={16}>
                <Space direction={"vertical"} size={[10, 10]} style={{marginBottom: 15}}>
                    <Form.List name="accounts">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row gutter={[10, 10]}>
                                        <Col span={4}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'tecOrderId']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("TECORDER.ID")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'tecOrderSellerId']}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("TECORDER.SELLER_ID")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'defaultShippingAddress']}
                                                rules={[{ required: true }]}
                                            >
                                                <UIInputWidgetAccountAddress shippingAddresses={account?.shippingAddresses} disabled={loading} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'useGolda']}
                                                valuePropName={"checked"}
                                            >
                                                <Checkbox
                                                    className="checkbox-small"
                                                    disabled={loading}
                                                    onChange={(e) =>
                                                        !e.target.checked &&
                                                        form.setFieldsValue({
                                                            number: account?.agent.number
                                                        })
                                                    }
                                                >
                                                    {t("TECORDER.USE_GOLDA")}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button onClick={() => remove(name)} block>X</Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Space direction={"vertical"} size={[10, 10]}>
                                        <Button type="dashed" onClick={() => add()} block>Add TecOrder account</Button>
                                    </Space>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Space>
            </Col>
        </Row>
        <Row gutter={[30, 30]} align={"top"}>
            <Col span={24}>
                <Button type={"primary"} onClick={() => form.submit()} disabled={loading}>{t("SAVE")}</Button>
            </Col>
        </Row>
    </Form>
});
