import React, {useState} from "react";
import {Form, Modal, Row, Col, InputNumber} from "antd";
import {Parcel} from "../../../../../models/parcels/shipment";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";

interface ParcelEditModalProps {
    parcel: Parcel,
    onClose: () => void,
    onSuccess: () => void,
}
export const ParcelEditModal: React.FC<ParcelEditModalProps> = ({parcel, onClose, onSuccess}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const initialValues = {
        width:       parcel.width,
        height:      parcel.height,
        length:      parcel.length,
        netWeight:   parcel.netWeight,
        grossWeight: parcel.grossWeight
    }

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={"Details #" + parcel.number}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={values => {
                    form.validateFields().then(async () => {
                        setLoading(true);
                        try {
                            await shipmentsService.updateParcelDetails(parcel.id, values);
                            onSuccess();
                            onClose();
                        } catch (e: any) {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                        } finally {
                            setLoading(false);
                        }
                    })
                }}
            >
                <Row gutter={10}>
                    <Col span={8}>
                    <Form.Item
                            name="width"
                            label={"Width"}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <InputNumber disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="length"
                            label={"Length"}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <InputNumber disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="height"
                            label={"Height"}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <InputNumber disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="netWeight"
                            label={"Net weight"}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <InputNumber disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="grossWeight"
                            label={"Gross weight"}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <InputNumber disabled={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
