import React from "react";
import {WidgetProps} from "../widget";
import {Select} from "antd";

export const UIInputWidgetCustomSelect: React.FC<WidgetProps> = ({value, onChange, multiple, defaultOptions}) => {

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            style={{width: '100%'}}
        >
            {(defaultOptions || []).map(value =>
                <Select.Option key={value} value={value}>{value}</Select.Option>)}
        </Select>
    )
}