import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useParams} from "react-router-dom";
import {PropertyInventoryTaskPart, PropertyInventoryTaskProperty} from "../../../../models/property/property";
import {propertyService} from "../../../../services/property/property.service";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Button, Col, Descriptions, Row, Tooltip, Typography} from "antd";
import {useNavigate} from "react-router";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {columnFactory} from "./part-write-off.inventoty-tasks.inventory.columns";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {SmartAction} from "../../../common/smart-table/smart-table.model";
import {EditQtyModal} from "./edit-qty-modal/edit-qty.modal";
import Date from "../../../date/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EditDataModal} from "./edit-data-modal/edit-data.modal";

export const InventoryTasksPartWriteOff: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const navigate = useNavigate();

    const {id} = useParams<{ id: string }>();

    const [part, setPart] = useState<PropertyInventoryTaskPart | null>(null);

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getInventoryWriteOffPartProperties(parseInt(id!), r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    const [editModalProperty, setEditModalProperty] = useState<PropertyInventoryTaskProperty | null>(null);
    const [editModalShown, setEditModalShown] = useState<boolean>(false);

    const reloadPart = () => {
        propertyService.getInventoryPart(parseInt(id!)).then(part => setPart(part));
    }

    const close = async () => {
        await propertyService.closeInventoryPart(parseInt(id!));
        navigate("/property/inventory-tasks/" + part?.task.id);
    }

    const onPropertyModalClose = () => {
        setEditModalProperty(null);
        handler.reloadItemsWithLastRequest();
    }

    const onModalClose = () => {
        setEditModalShown(false);
        reloadPart();
    }

    useEffect(() => {
        reloadPart();
    }, [id]);

    if (!part) {
        return <LoadingSpin/>
    }

    const actions: SmartAction<any>[] = [
        {
            icon: 'edit',
            title: 'Edit Qty',
            onClick: (item: PropertyInventoryTaskProperty) => setEditModalProperty(item)
        }
    ]

    return (
        <>
            <PageHeader
                style={{ padding: "0 0 10px 0" }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("PROPERTY.INVENTORY_TASK_PART_WRITE_OFF.TITLE")}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/property/inventory-tasks/" + part?.task.id);
                }}
                extra={
                    <Button
                        type={"primary"}
                        onClick={close}
                    >
                        {t("CLOSE")}
                    </Button>
                }
            />
            <Row gutter={[25, 25]}>
                <Col span={24}>
                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 5, xl: 5, lg: 5, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.TYPE")}>
                                    {part.type.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("QTY")}>
                                    {part.qty}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_CONFIRMED_QTY.TITLE")}>
                                    {part.confirmedQty}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_DAMAGED_QTY.TITLE")}>
                                    {part.destroyedQty}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_LOST_QTY.TITLE")}>
                                    {part.lostQty}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.RESPONSIBLE_PERSON")}>
                                    {part.responsiblePersons.map(p => p.firstName + ' ' + p.lastName).join(', ')}
                                </Descriptions.Item>
                                {part.document ? (
                                    <>
                                        <Descriptions.Item label={t("DATE")}>
                                            <Date dateString={part.document.date} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_DOCUMENT_NUMBER.TITLE")}>
                                            {part.document.number}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_ACCOUNTANT.TITLE")}>
                                            {part.document.accountant.firstName + ' ' + part.document.accountant.lastName}
                                        </Descriptions.Item>
                                    </>
                                ) : null}
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => setEditModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <SmartTable
                        handler={handler}
                        columns={columns}
                        actions={actions}
                    />
                </Col>
            </Row>
            {editModalProperty ? (
                <EditQtyModal property={editModalProperty} onClose={() => onPropertyModalClose()} />
            ) : null}
            {editModalShown ? (
                <EditDataModal part={part} onClose={() => onModalClose()} />
            ) : null}
        </>
    )
}