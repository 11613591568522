import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useParams} from "react-router-dom";
import {propertyService} from "../../../../services/property/property.service";
import {Property} from "../../../../models/property/property";
import {Button, Col, Descriptions, Dropdown, Menu, Row, Space, Typography} from "antd";
import Date from "../../../date/date";
import {useNavigate} from "react-router";
import {useTranslate} from "../../../../hooks/translate.hook";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {displayPrice} from "../../../../models/prices/price.helper";
import {PropertyType} from "../common/property-type/property-type";
import {PropertyCode} from "../common/property-code/property-code";
import {ReviewListPropertyView} from "./review-list/review-list.property.view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {EditPropertyModal} from "../modal/edit/edit.property.modal";
import {PageHeader} from "@ant-design/pro-components";
import {ChangesListPropertyView} from "./changes-list/changes-list.property.view";
import {FilesListPropertyView} from "./files-list/files-list.property.view";
import {DecreaseQtyPropertyModal} from "../actions/decrease-qty/decrease-qty.property.modal";
import {IncreaseQtyPropertyModal} from "../actions/increase-qty/increase-qty.property.modal";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {isAllowed} from "../../../../utils/helpers";
import {ChangeLocationPropertyModal} from "../modal/change-location/change-location.property.modal";

export const PropertyView: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const navigate = useNavigate();

    const { id } = useParams<{ id: string }>();

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const [property, setProperty] = useState<Property | null>(null);

    const [editableModalShown, setEditableModalShown] = useState<boolean>(false);
    const [changeLocationModalShown, setChangeLocationModalShown] = useState<boolean>(false);

    const [increaseQtyModalShown, setIncreaseQtyModalShown] = useState<boolean>(false);
    const [decreaseQtyModalShown, setDecreaseQtyModalShown] = useState<boolean>(false);

    const reload = async () => {
        setProperty(null);
        await propertyService.get(parseInt(id!)).then(property => setProperty(property));
    }

    useEffect(() => {
        reload();
    }, [id]);

    if (!property) {
        return <LoadingSpin />
    }

    const getSticker = () => {
        propertyService.getPropertySticker(property.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const getCompactSticker = () => {
        propertyService.getPropertyCompactSticker(property.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const onEditableModalClosed = async () => {
        setEditableModalShown(false);
        setChangeLocationModalShown(false);
        await reload();
    }

    const onChangeQtyModalClosed = async () => {
        setIncreaseQtyModalShown(false);
        setDecreaseQtyModalShown(false);
        await reload();
    }

    return (
        <>
            <PageHeader
                style={{ padding: '0 0 10px 0' }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("PROPERTY.PROPERTY.TITLE")}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/property");
                }}
                extra={<PropertyType type={property.type} />}
            />
            <Row justify={"space-between"} style={{marginBottom: 20}} gutter={[10, 10]}>
                <Col>
                    <Space direction={"horizontal"}>
                        <Button
                            icon={<FontAwesomeIcon icon={["fas", "sticky-note"]} />}
                            onClick={getCompactSticker}
                        >
                            Get compact label
                        </Button>
                        <Button
                            icon={<FontAwesomeIcon icon={["fas", "sticky-note"]} />}
                            onClick={getSticker}
                        >
                            Get full label
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Dropdown
                        placement="bottomRight"
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {isAllowed(attributes, "property_management") ? (
                                    <>
                                        <Menu.Item
                                            onClick={() => setEditableModalShown(true)}
                                        >
                                            Edit
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => setDecreaseQtyModalShown(true)}
                                        >
                                            Write off
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => setIncreaseQtyModalShown(true)}
                                        >
                                            Top up
                                        </Menu.Item>
                                    </>
                                ) : (
                                    <>
                                        <Menu.Item
                                            onClick={() => setChangeLocationModalShown(true)}
                                        >
                                            Change Location
                                        </Menu.Item>
                                    </>
                                )}
                            </Menu>
                        }
                    >
                        <Button type="primary">{t("ACTION")}</Button>
                    </Dropdown>
                </Col>
            </Row>
            <Row gutter={[35, 35]}>
                <Col span={24}>
                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.CODE")}>
                                    <PropertyCode property={property} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.QTY")}>
                                    {property.qty}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.PURCHASE_DATE")}>
                                    <Date dateString={property.purchaseDate} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.PURCHASE_AMOUNT")}>
                                    {displayPrice(property.purchaseAmount)}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.RESPONSIBLE_PERSON")}>
                                    {property.responsiblePerson.firstName} {property.responsiblePerson.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.SUPPLIER")}>
                                    {property.supplier}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.LOCATION")}>
                                    {'(' + property.location.code + ') ' + property.location.name}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Typography.Title level={3} style={{marginBottom: 25}}>Files</Typography.Title>
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <FilesListPropertyView property={property} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Typography.Title level={3} style={{marginBottom: 10}}>Reviews</Typography.Title>
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <ReviewListPropertyView property={property} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Typography.Title level={3} style={{marginBottom: 10}}>Documents</Typography.Title>
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <ChangesListPropertyView property={property} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {editableModalShown ? (
                <EditPropertyModal onClose={() => onEditableModalClosed()} property={property} />
            ) : null}
            {changeLocationModalShown ? (
                <ChangeLocationPropertyModal onClose={() => onEditableModalClosed()} property={property} />
            ) : null}
            {decreaseQtyModalShown ? (
                <DecreaseQtyPropertyModal onClose={() => onChangeQtyModalClosed()} property={property} />
            ) : null}
            {increaseQtyModalShown ? (
                <IncreaseQtyPropertyModal onClose={() => onChangeQtyModalClosed()} property={property} />
            ) : null}
        </>
    )
}